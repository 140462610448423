.consulting {

  >[class^=grid-] {
    padding-bottom: 0;
  }

  li {
    &:last-child {
      >.number-box {

        @include breakpoint(medium only) {
          height: $pn-consulting-list-item-last-height-medium;
        }

        @include breakpoint(large) {
          height: $pn-consulting-list-item-last-height-large;
        }
      }
    }
  }

  .pictogram-container {
    &::after {
      background-image: $pn-svg-consulting;
    }
  }

  .number-box {
    float: left;
    background: $pn-consulting-list-number-box-background;

    @include breakpoint(medium only) {
      height: $pn-consulting-list-item-height-medium;
      margin-right: $pn-consulting-list-number-box-margin-right-medium;
    }

    @include breakpoint(large) {
      height: $pn-consulting-list-item-height-large;
      margin-right: $pn-consulting-list-number-box-margin-right-large;
    }
  }

  .number-wrapper {
    position: relative;
    border-radius: $pn-consulting-list-number-radius;
    background: $pn-consulting-list-number-wrapper-background;

    @include breakpoint(medium only) {
      bottom: rem-calc(5px);
      width: $pn-consulting-list-number-wrapper-width-medium;
      height: $pn-consulting-list-number-wrapper-width-medium;
    }

    @include breakpoint(large) {
      bottom: rem-calc(9px);
      width: $pn-consulting-list-number-wrapper-width-large;
      height: $pn-consulting-list-number-wrapper-width-large;
    }
  }

  .number {
    position: relative;
    border-radius: $pn-consulting-list-number-radius;
    background: $pn-consulting-list-number-background;
    text-align: center;
    font-weight: $pn-consulting-list-title-font-weight;
    color: $pn-consulting-list-number-color;

    @include breakpoint(medium only) {
      $centering-offset: ($pn-consulting-list-number-wrapper-width-medium - $pn-consulting-list-number-diameter-medium) / 2;

      top: $centering-offset;
      left: $centering-offset;
      width: $pn-consulting-list-number-diameter-medium;
      height: $pn-consulting-list-number-diameter-medium;
      line-height: $pn-consulting-list-number-diameter-medium;
      font-size: $pn-consulting-list-number-font-size-medium;
    }

    @include breakpoint(large) {
      $centering-offset: ($pn-consulting-list-number-wrapper-width-large - $pn-consulting-list-number-diameter-large) / 2;

      top: $centering-offset;
      left: $centering-offset;
      width: $pn-consulting-list-number-diameter-large;
      height: $pn-consulting-list-number-diameter-large;
      line-height: $pn-consulting-list-number-diameter-large;
      font-size: $pn-consulting-list-number-font-size-large;
    }
  }

  .title {
    font-weight: $pn-consulting-list-title-font-weight;

    @include breakpoint(medium only) {
      line-height: $pn-consulting-list-title-line-height-medium;
      font-size: $pn-consulting-list-title-font-size-medium;
    }

    @include breakpoint(large) {
      line-height: $pn-consulting-list-title-line-height-large;
      font-size: $pn-consulting-list-title-font-size-large;
    }
  }

  .subtitle {
    line-height: $pn-consulting-list-subtitle-line-height;
    font-size: $pn-consulting-list-subtitle-font-size;
    color: $pn-consulting-list-subtitle-color;
  }
}