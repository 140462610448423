$single-post-left-padding: 6rem;

body.single-post {
  background-color: #ffffff;
  .background-container {
    background-color: #ffffff;
  }
  #header_subpage {
    h2 {
      @include breakpoint(medium only) {
        padding-left: 4rem;
      }
      @include breakpoint(small only) {
        padding-left: 0;
        font-size: 2.5rem;
        line-height: 3rem;
      }
      padding-left: $single-post-left-padding;
      font-size: 4rem;
      text-transform: none;
      color: #0a1e47;
      padding-bottom: 0;
      margin-bottom: 1rem;
      &:after {
        content: "";
      }
    }
  }
  #menu-wrapper {
    a,
    button {
      color: #0f224b;
    }
  }
  .off-canvas-content {
    .post-caption {
      @include breakpoint(small only) {
        padding-left: 0;
      }
      font-size: 14px;
      margin-bottom: 3rem;
      color: #9e9e9e;
      padding-left: $single-post-left-padding;

      span,
      a {
        padding-right: 0.5rem;
        padding-left: 0.5rem;

        &.date {
          padding-left: 0px;
        }
      }
    }
  }
  .content {
    .lead {
      p {
        font-size: 1.15rem;
        line-height: 1.7rem;
        max-width: calc(100% - 6rem) !important;
        width: calc(100% - 6rem) !important;
        margin-left: $single-post-left-padding;
        padding-right: 2.5rem;
        @include breakpoint(medium only) {
          margin-right: 3rem;
          margin-left: 3rem;
          padding-right: 0;
        }
        @include breakpoint(small only) {
          margin-left: 0;
          margin-right: 0;
          padding-right: 0;
          max-width: calc(100%) !important;
          width: calc(100%) !important;
        }
        img {
          @include breakpoint(small only) {
            width: 100% !important;
          }
        }
      }
    }
    > *:not(.lead):not(ul):not(ol) {
      max-width: calc(100% - 6rem) !important;
      //width: calc(100% - 6rem) !important;
      font-size: 1.15rem;
      line-height: 1.7rem;
      @include breakpoint(medium only) {
        margin-right: 3rem;
        margin-left: 3rem;
      }
      @include breakpoint(small only) {
        margin-left: 0;
        margin-right: 0;
        padding-right: 0;
        max-width: 100% !important;
        width: 100% !important;
      }
      margin-left: $single-post-left-padding;
      padding-right: 2.5rem;
      img {
        @include breakpoint(small only) {
          margin-left: 0;
          max-width: 100% !important;
          width: 100% !important;
        }
        @include breakpoint(medium only) {
          max-width: 100% !important;
          width: 100% !important;
          margin-left: 0;
        }
        margin-left: -6rem;
        width: calc(100% + 6rem) !important;
        max-width: calc(100% + 6rem) !important;
      }
    }
    > ol,
    > ul {
      font-size: 1.15rem;
      line-height: 1.7rem;
      @include breakpoint(large only) {
        margin-left: 7.2rem !important;
        width: calc(100% - 7.2rem) !important;
        max-width: calc(100% - 7.2rem) !important;
      }
      @include breakpoint(small only) {
        margin-left: 1.2rem !important;
        width: calc(100% - 1.2rem) !important;
        max-width: calc(100% - 1.2rem) !important;
      }
      @include breakpoint(medium only) {
        margin-right: 4.2rem !important;
        margin-left: 4.2rem !important;
        padding: 0;
      }
    }
    .download-box {
      @include breakpoint(small only) {
        padding: 1rem;
        padding-left: 0.6rem;
      }
      margin-right: 4rem;
      background: #f0f0f0;
      box-shadow: none;
      border: 0.1rem solid #e0e1e6;
      padding: 2rem;
      &.bottom-download-box {
        @include breakpoint(small only) {
          padding-left: 0.6rem;
        }
        .title {
          @include breakpoint(small only) {
            margin: 0;
          }
        }
        padding: 1rem;
        .description p {
          margin-bottom: 0;
        }
        .download-btn {
          @include breakpoint(small only) {
            margin-top: 1rem;
          }
        }
      }
      .download-btn {
        width: auto;
        padding: 0.6rem 1.5rem;
        font-size: 1rem;
      }
      .sheet {
        @include breakpoint(small only) {
          width: 28px;
          height: 50px;
          margin-left: 0.5rem;
        }
        background: url("../images/sheet.svg");
        width: 50px;
        height: 60px;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .download-box-inner {
        padding-left: 0.5rem;
      }
      .title {
        @include breakpoint(small only) {
          margin-left: 2.5rem;
          margin-top: -2.8rem;
        }
        font-weight: 800;
      }
    }
    .lead {
      p {
        font-weight: 600;
      }
    }
    // & > *:not(.wp-caption):not(ul):not(ol) {
    .wp-caption {
      @include breakpoint(medium only) {
        padding: 2rem;
        width: 100% !important;
      }
      @include breakpoint(small only) {
        padding: 0rem;
        width: 100% !important;
      }
      margin-top: 3rem;
      img {
        @include breakpoint(medium only) {
          width: 100%;
          display: block;
        }
        @include breakpoint(small only) {
          width: 100%;
          display: block;
        }
      }
    }
    .wp-caption-text {
      padding-right: 0;
      a {
        font-weight: bold;
        font-size: inherit !important;
        &:hover {
          // text-decoration: underline;
        }
      }
      text-align: right;
      font-size: 13px;
      color: #aaaaaa;
      margin-top: 0.25rem;
    }
    blockquote {
      font-style: italic;
      font-weight: 600;
      padding-top: 0;
      padding-left: 0;
      border-left: 0;
      p {
        display: flex;
        color: #e34c3e;
        &::before {
          width: 20px;
          content: "";
          margin-right: 2rem;
          background: url(../images/stripes.svg);
          background-repeat-x: no-repeat;
          border-radius: 5px;
        }
      }
    }
    h2 {
      margin-top: 1rem;
      height: auto;
      font-size: 1.4rem;
      &:after {
        content: "";
      }
    }
    ul,
    ol {
      @include breakpoint(medium only) {
        padding-right: 0;
      }
      @include breakpoint(small only) {
        margin-right: 0rem;
        margin-left: 1.5rem;
        padding-right: 0;
      }

      padding-right: 2.5rem;
      > li {
        margin-bottom: 0.75rem;
      }
    }
    .social {
      border-top: 1px solid #eeeeee;
      margin-bottom: 3rem;
      padding-bottom: 1rem;
      padding-top: 2rem;
      margin-top: 3rem;
      display: flex;
      flex-direction: row;
      a {
        width: 30px;
        height: 30px;
        margin-right: 1rem;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .twitter {
        background: url("../images/twitter.svg");
        width: 35px;
      }
      .facebook {
        background: url("../images/facebook.svg");
      }
      .linkedin {
        background: url("../images/linkedin.svg");
      }
    }
  }
  .related-posts,
  .authors {
    .lead {
      color: #e34c3e;
      font-size: 0.9rem;
      margin-bottom: 1rem;
      display: block;
    }
    article {
      .image {
        @include breakpoint(small only) {
          width: 100%;
          height: 200px;
        }
        display: block;
        width: 100%;
        max-width: 267px;
        height: 155px;
        background-size: cover;
        background-position: center;
        border-radius: 4px;
      }
      h3 {
        font-size: 1rem;
        margin-top: 0.5rem;
        a {
          color: #0a1d48;
        }
      }
      p {
        font-size: 0.9rem;
        color: #9e9e9e;
      }
    }
    .all-articles {
      text-align: right;
      a {
        font-size: 0.9rem;
        &:after {
          position: relative;
          border: none !important;
          font-family: "Material Icons";
          display: inline-block;
          margin-right: 0.25rem;
          content: "\e5c5";
          -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
          top: 0.1rem;
        }
      }
    }

    .contact-us {
      padding: 10px 0px;
      margin-bottom: 60px;

      &:after {
        display: none !important;
      }
    }
  }

  .authors-mobile {
    margin: 2rem 6rem;
    border-radius: 5px;
    padding: 1rem 2rem;
    background: #f7f7f7;
    align-items: center;
    .all-articles {
      flex-grow: 1;
      a {
        margin: 0;
        padding: 0 1rem;
        height: 50px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 16px;
        width: 120px;
        margin-left: auto;
      }
    }
    @include breakpoint(large only) {
      display: none;
    }
    @include breakpoint(medium only) {
      display: flex;
      flex-direction: row;
      .author {
        width: unset;
        padding-right: 2rem;
        margin-bottom: 0;
      }
    }
    @include breakpoint(small only) {
      width: 100%;
      margin: 0;
      margin-bottom: 2rem;
      .all-articles {
        padding: 0;
        a {
          width: 100%;
        }
      }
    }
  }
}
