#form,
#form-sticky {
  background: $pn-form-background;
  text-align: center;
  color: $pn-form-color-primary;

  @include breakpoint(small only) {
    margin-top: $pn-form-margin-top-small;
    padding-top: $pn-form-padding-top-small;
  }

  @include breakpoint(medium only) {
    margin-top: $pn-form-margin-top-medium;
    padding-top: $pn-form-padding-top-medium;
  }

  @include breakpoint(large) {
    margin-top: $pn-form-margin-top-large;
    padding-top: $pn-form-padding-top-large;
  }

  h1 {
    margin-bottom: 0;

    @include breakpoint(small only) {
      font-size: $pn-form-h1-font-size-small;
    }

    @include breakpoint(medium only) {
      font-size: $pn-form-h1-font-size-medium;
    }

    @include breakpoint(large) {
      font-size: $pn-form-h1-font-size-large;
    }

    &::after {
      display: block;
      font-size: 0;

      @include breakpoint(small only) {
        content: $pn-svg-stripe;
        margin: $pn-form-stripe-margin-small;
      }

      @include breakpoint(medium only) {
        content: $pn-svg-stripe-narrow;
        margin: $pn-form-stripe-margin-medium;
      }

      @include breakpoint(large) {
        content: $pn-svg-stripe;
        margin: $pn-form-stripe-margin-large;
      }
    }
  }

  p {
    color: $pn-form-color-secondary;

    @include breakpoint(small only) {
      line-height: $pn-header-p-line-height-small;
      font-size: $pn-header-p-font-size-small;
    }

    @include breakpoint(medium only) {
      line-height: $pn-header-p-line-height-medium;
      font-size: $pn-header-p-font-size-medium;
    }

    @include breakpoint(large) {
      line-height: $pn-header-p-line-height-large;
      font-size: $pn-header-p-font-size-large;
    }
  }

  button {
    height: $pn-form-button-height;
    line-height: $pn-form-button-height;
    margin-bottom: 0;

    @include breakpoint(small only) {
      margin-top: $pn-form-button-margin-top-small;
    }

    @include breakpoint(medium only) {
      margin-top: $pn-form-button-margin-top-medium;
    }

    @include breakpoint(large) {
      margin-top: $pn-form-button-margin-top-large;
    }

    &.formfield {
      margin-top: $pn-form-input-padding-top;
    }

    &[type="submit"],
    &.button.success {
      font-weight: $pn-form-submit-font-weight;

      @include breakpoint(small only) {
        margin-top: $pn-form-submit-margin-top-small;
        font-size: $pn-form-submit-font-size-small;
      }

      @include breakpoint(medium only) {
        margin-top: $pn-form-submit-margin-top-medium;
        font-size: $pn-form-submit-font-size-medium;
      }

      @include breakpoint(large) {
        margin-top: $pn-form-submit-margin-top-large;
        font-size: $pn-form-submit-font-size-large;
      }
    }

    &[type="submit"] {
      text-transform: uppercase;
    }

    &.button.success {
      background: #28bb10;
      color: $pn-form-submit-success-color;
    }
  }

  form {
    height: 0;
    overflow: hidden;
    margin: $pn-form-form-margin;
    transition: height $pn-form-form-transition-duration;

    &.open {
      height: 100%;
    }

    p {
      text-align: left;
      color: $pn-form-form-p-color;

      @include breakpoint(small only) {
        line-height: $pn-form-form-p-line-height-small;
        font-size: $pn-form-form-p-font-size-small;
      }

      @include breakpoint(medium only) {
        line-height: $pn-form-form-p-line-height-medium;
        font-size: $pn-form-form-p-font-size-medium;
      }

      @include breakpoint(large) {
        line-height: $pn-form-form-p-line-height-large;
        font-size: $pn-form-form-p-font-size-large;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &:empty {
        display: none; // for inline Markdown rendering
      }
    }
  }

  select {
    display: none !important;
  }

  input,
  textarea,
  button.formfield {
    margin-bottom: $pn-form-field-margin-bottom - $pn-form-ffl-offset;
    border-radius: $pn-form-field-border-radius;
  }

  input,
  textarea {
    font-size: $pn-form-field-font-size;
    transition: background $pn-form-field-transition-duration;
  }

  input,
  button.formfield {
    height: $pn-form-field-height !important;
  }

  input {
    padding-top: $pn-form-input-padding-top;
  }

  textarea {
    height: $pn-form-textarea-height;
    padding-top: $pn-form-textarea-padding-top;
    padding-bottom: $pn-form-textarea-padding-bottom;
  }

  .ffl-wrapper {
    @include floating-form-labels($pn-form-ffl-offset, $pn-form-ffl-offset);

    &.focused {
      > .ffl-label {
        color: $pn-button-primary-background;
      }

      input,
      textarea {
        background: $pn-form-field-focused-background;
        caret-color: $pn-button-primary-background;
      }
    }

    > .ffl-label {
      line-height: $pn-form-field-height;
      padding-left: $pn-input-padding-sides;
      text-align: left;
      font-size: $pn-form-label-font-size;
      color: $pn-form-label-color;

      &.required {
        &::after {
          content: " *";
          color: $pn-form-label-required-color;
        }
      }
    }

    &.ffl-floated {
      > .ffl-label {
        font-size: $pn-form-label-floated-font-size;
      }
    }
  }

  .agree {
    position: relative;

    @include breakpoint(small only) {
      margin-top: $pn-form-agree-margin-top-small;
      margin-bottom: $pn-form-agree-margin-bottom-small;
    }

    @include breakpoint(medium only) {
      margin-top: $pn-form-agree-margin-top-medium;
      margin-bottom: $pn-form-agree-margin-bottom-medium;
    }

    @include breakpoint(large) {
      margin-top: $pn-form-agree-margin-top-large;
      margin-bottom: $pn-form-agree-margin-bottom-large;
    }

    label {
      margin: 0;

      &::before {
        content: "";
        display: flex;
        float: left;
        border-radius: $pn-form-agree-checkbox-border-radius;
        font-family: "Material Icons";
        font-size: $pn-form-agree-checkbox-font-size;
        background: $pn-form-agree-checkbox-background;
        color: $pn-form-agree-checkbox-color;

        @include flex-align(center, middle);

        @include breakpoint(small only) {
          width: $pn-form-agree-checkbox-width-small;
          height: $pn-form-agree-checkbox-width-small;
        }

        @include breakpoint(medium only) {
          width: $pn-form-agree-checkbox-width-medium;
          height: $pn-form-agree-checkbox-width-medium;
        }

        @include breakpoint(large) {
          width: $pn-form-agree-checkbox-width-large;
          height: $pn-form-agree-checkbox-width-large;
        }
      }
    }

    [type="checkbox"] {
      position: absolute;
      height: auto !important;
      margin: 0;
      opacity: 0;
      cursor: pointer;

      &:checked {
        + label {
          &::before {
            content: "\e5ca";
            background: $pn-form-agree-checkbox-checked-background;
          }
        }
      }
    }

    p {
      margin-bottom: 0;

      @include breakpoint(small only) {
        margin-left: $pn-form-agree-p-margin-left-small;
      }

      @include breakpoint(medium only) {
        margin-left: $pn-form-agree-p-margin-left-medium;
      }

      @include breakpoint(large) {
        margin-left: $pn-form-agree-p-margin-left-large;
      }
    }
  }

  .ortext {
    margin-top: 2rem;

    p {
      color: $pn-form-form-p-color;

      @include breakpoint(small only) {
        font-size: $pn-form-ortext-font-size-small;
      }
    }

    &.form-open {
      margin-top: 5rem;
    }
  }

  p.contact-sent {
    font-size: 30px;
    color: #ffffff;
    background: #e54843;
    font-size: 1.5rem;
    padding: 1rem;
    &:before {
      content: "\e5ca";
      font-family: "Material Icons";
    }
  }
}

#form-sticky {
  margin-top: 0;
  padding-top: 0;

  padding: 1rem;

  form {
    margin: 0;
  }
  .page-1 {
    #contact-sticky-form {
      background-color: #fff;
    }
    .agree {
      margin-top: 1rem !important;
    }
  }
}

#form-sticky-selected-service {
  color: white;
}

.help_form_response-error {
  background: #fde9e7;
  border: 2px solid red;
  padding: 1rem !important;
  border-radius: 5px;
  margin-bottom: 1.5rem !important;
  color: red !important;
  text-align: center !important;
  margin-top: 1rem !important;
}

#contact-sticky-form {
  input,
  button.dropdown {
    height: 2.5rem !important;
  }
  button.dropdown {
    span {
      line-height: 2.6rem;
    }
    &:after {
      top: -0.25rem;
      margin-left: 0;
      padding-right: 0;
      right: 1.1rem;
    }
    background: #f0f0f0 !important;
    border-radius: 0;
  }
  input {
    padding-left: 0.7rem !important;
  }
  input:not(.is-invalid-input) {
    border-radius: 0;
    background: #f0f0f0 !important;
  }
  .ffl-wrapper {
    .ffl-label {
      line-height: 2.45rem !important;
      padding-left: 0.7rem;
    }
    &.ffl-floated {
      .ffl-label {
        margin-top: 5px;
      }
    }
  }
}
