header {
  .arrow {
    @include breakpoint(medium only) {
      font-size: 1.25rem;
    }
  }

  @include breakpoint(small only) {
    > .grid-container {
      padding: 0;
    }
  }
}

#arrow-cell {
  text-align: left;

  @include breakpoint(medium only) {
    padding: 0 1.875rem;
  }

  @include breakpoint(small only) {
    padding: 0 0.875rem;
  }
}

body.page-template-page-no-header #content-without-header {
  padding-top: 3rem;
}

#header_subpage {
  @include breakpoint(medium only) {
    padding: 1.875rem;
    padding-bottom: 0;
  }

  text-align: left;

  body.page-template-page-no-header & {
    padding-bottom: 3rem;
  }

  .cell {
    text-align: left !important;
  }

  h2 {
    &:after {
      @include breakpoint(medium only) {
        content: "";
      }

      @include breakpoint(small only) {
        content: "";
      }
    }

    @include breakpoint(small only) {
      margin-top: 50%;
      font-size: 1.5rem;
      line-height: 2rem;
      padding-bottom: 1rem;
      margin-bottom: 0px;
    }

    @include breakpoint(medium only) {
      margin-bottom: 0px;
      font-size: 2.25rem;
    }

    padding-bottom: 1rem;
    height: unset;
    font-family: Malva, Lato, "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;

    text-transform: uppercase;
    font-size: 3em;
    color: #ffffff;
    line-height: 1em;
    margin-bottom: 2.5625rem;
  }

  p {
    margin-top: 0px !important;
    margin-bottom: 1.5625rem;
    line-height: 1.375rem;
    font-size: 0.9375rem;
  }
}

%icon {
  font-family: "Material Icons";
  font-size: 2rem;
  color: black;
  margin-right: 1.5rem;
}

.number-block {
  position: relative;
  border-radius: 4px;
  cursor: pointer !important;

  width: 3rem;
  height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;

  position: relative;
  background: $pn-consulting-list-number-background;
  text-align: center;
  font-weight: $pn-consulting-list-title-font-weight;
  color: $pn-consulting-list-number-color;
}

.accordion-card {
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 0.625rem;
  }

  > .grid-x {
    padding: 1.75rem;

    @include breakpoint(small only) {
      padding: 0.4rem;
    }

    &.collapse:first-of-type {
      padding-bottom: 0px;
    }

    &.collapse:last-of-type {
      padding-top: 0px;
    }

    .description {
      @include breakpoint(small only) {
        & > *:not(h3) {
          margin-left: 3rem;
        }
      }

      .description-text {
        @include breakpoint(small only) {
          padding-left: 3rem;
          margin-top: 0.5rem;
        }

        p:first-child {
          margin-bottom: 0.5em;
        }
      }
    }

    .action {
      text-align: right;
    }

    &.more-content {
      padding: 2rem;
      padding-bottom: 0;
      padding-top: 1rem;

      > .cell:last-of-type {
        align-self: flex-end;
      }

      hr:first-child {
        margin-top: 0px;
        margin-bottom: 2rem;
      }

      h4 {
        font-size: 1em !important;
      }

      @include breakpoint(small only) {
        padding-bottom: 0;
        padding-left: 0rem;
        padding-top: 1rem;
        padding-right: 2rem;
      }

      @include breakpoint(medium only) {
        padding-top: 1rem;
        padding-left: 0;
      }
    }
  }

  > .grid-y {
    .more-content {
      padding-left: 2.5rem;
      padding-top: 0.5rem;
    }
  }

  .dropdown {
    &:before {
      @extend %icon;
      margin-right: 0;
      content: "\e315";
      transform: rotate(90deg);
      display: block;
      margin-top: 0.25rem;
    }

    @include breakpoint(large) {
      &:before {
        margin-top: 0rem;
      }
    }

    &:hover {
      background: #dfe0e4;
    }

    &::after {
      content: "";
    }

    @include breakpoint(small only) {
      right: 0.5rem;

      &:before {
        margin-top: 0.5rem;
        display: block;
      }
    }

    background: #f0f0f0;
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 20px;

    right: 1.5rem;
  }

  &.is-active {
    .chevron {
      &:before {
        transform: rotate(270deg);
      }
    }

    .dropdown {
      &:before {
        margin-right: 0;
        @extend %icon;
        content: "\e5cd";
        font-size: 2rem;
      }

      &.chevron {
        outline: none;
        transform: rotate(270deg);

        &:before {
          content: "\e315";
        }
      }
    }

    .accordion-title {
      @include breakpoint(large) {
        padding-bottom: 0.5rem;
      }
    }
  }

  .button {
    margin-bottom: 3rem;

    @include breakpoint(small only) {
      margin: 0;
    }
  }

  .number-list {
    @include breakpoint(small only) {
      display: block !important;
      padding-right: 2rem;
    }
  }
}

h2 {
  &.big-icon {
    @include breakpoint(small only) {
      margin-bottom: 1rem;
    }

    @include breakpoint(medium) {
      padding-left: 8rem;
      font-size: 2.25rem;
      height: 6rem;
      display: flex;
      align-items: center;
    }

    .service-icon {
      content: ".";
      width: rem-calc(96px);
      height: rem-calc(96px);
      position: absolute;
      left: 0px;
      top: 0px;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 3px;
    }
  }

  &.small-icon {
    padding-left: 3.25rem;
    font-size: 1.5rem;
    height: 3rem;
    display: flex;
    align-items: center;

    @include breakpoint(medium only) {
      height: 2rem;
      font-size: 1.2rem;
    }

    @include breakpoint(small only) {
      font-size: 1rem;
      align-items: center;
      margin-bottom: 0;
      height: 2rem;
      font-weight: 600;
      line-height: 1.3rem;
    }

    &:after {
      font-family: "Material Icons";
      content: "\e5ca";
      font-size: 1.5rem;
      width: 2rem;
      height: 2rem;
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #2eda68;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: 1rem;
    }

    @include breakpoint(small only) {
      &:after {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 1rem;
      }
    }

    &.chevron {
      padding: 0;
      padding-left: 3rem;
      margin-bottom: 0;
      @include breakpoint(small only) {
        margin-bottom: 0;
      }

      &:before {
        transform: rotate(90deg);
        content: "\e315";
        position: absolute;
        font-family: "Material Icons";
        right: 0;
        color: #12b2f9;
        font-size: 1.25rem;
      }
    }
  }
}

#menu-wrapper {
  a.active {
    font-weight: bold;
  }
}

section.full-width {
  padding-top: 1rem;
}

@mixin circleIcon($size) {
  &:before {
    position: absolute;
    top: -0.2rem * $size;
    left: -1.05rem * $size;
    content: attr(data-number);
    background: #042351;
    color: white;
    border-radius: 1em;
    padding: 0.1rem;
    width: 2rem * $size;
    height: 2rem * $size;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.13);
    font-weight: bold;
    font-size: 1.4rem * $size;
  }
}

.number-list {
  @include breakpoint(small only) {
    display: none;
  }

  list-style-type: none;
  padding-left: 1rem;

  > li[data-number] {
    position: relative;
    padding-left: 2rem;
    border-left: 3px solid #99ddff;
    margin-left: 1em;
    padding-bottom: 1em;

    @include circleIcon(0.6);

    &:last-of-type {
      @include breakpoint(small only) {
        border-left: 3px solid transparent;
      }

      @include breakpoint(medium only) {
        border-left: 3px solid transparent;
      }
    }

    p {
      margin-bottom: unset;
    }
  }

  &.red {
    li[data-number] {
      border-left: 3px solid #f4b7b3;

      &:before {
        @include breakpoint(large) {
          box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.13) !important;
        }
      }
    }
  }

  &.big {
    @include breakpoint(large only) {
      li[data-number] {
        @include circleIcon(1);
      }
    }
  }

  &.no-margin-left {
    padding-left: 0px;
    padding-top: 1rem;
    margin-left: -7px;
  }

  ul {
    margin-left: 30px !important;
    margin-top: 10px !important;
  }

  &.long {
    margin-bottom: 0px;

    @include breakpoint(large) {
      & > li:last-child {
        padding-bottom: 3rem;
      }
    }
  }
}

.remodal,
.vertical-off-canvas {
  .number-list {
    @include breakpoint(small only) {
      display: block;
    }
  }
}

.icon-list {
  padding-right: 2rem;
  list-style-type: none;
  line-height: 1.25rem;
  margin-left: 0;
  margin-bottom: 2rem !important;

  li {
    margin-bottom: 0;
    display: flex;
    padding-bottom: 1rem;

    &:before {
      @extend %icon;
      font-size: 1.25rem;
    }
  }

  &.small {
    li {
      &:before {
        font-size: 1.4rem;
        line-height: unset;
      }
    }
  }

  &.cross.red {
    li:before {
      color: #f91d04;
      content: "\e5cd";
    }
  }

  &.success.blue {
    li {
      margin-bottom: 0.5rem;
    }

    li:before {
      color: rgb(11, 169, 255);
      content: "\e5ca";
    }
  }
}

ul[data-smooth-scroll] {
  margin-bottom: 0;
}

button.button.get-in-touch {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  margin-bottom: 2rem;

  @include breakpoint(small only) {
    margin: 1.5rem auto;
  }

  @include breakpoint(medium only) {
    margin: 1.5rem auto;
  }

  &:after {
    content: "";
  }

  &:before {
    content: "";
  }
}

.card .date {
  color: #e24d3f;
  margin-bottom: 0.5rem;
}

.card .icon {
  background-repeat: no-repeat;
  width: 96px;
  height: 96px;
  background-size: contain;
  background-position: 50% 50%;
  margin-right: 20px;
  float: left;
}

.modal-close {
  height: 3rem;
  display: flex;
  width: 100%;
  padding: 1rem;
  background: #f7f7f7;
  align-items: center;
  border-bottom: 3px solid #f0f0f0;
  padding-left: 1rem;
  color: #0a1d48;

  &:hover {
    color: #e24f41;
  }

  &:before {
    @extend %icon;
    content: "\e315"; //vmiert nem jo az eredeti nyil kodja
    transform: scaleX(-1);
    color: #e24f41;
  }
}

.remodal .grid-container,
.vertical-off-canvas .grid-container {
  @include breakpoint(small only) {
    padding: 0px;
  }
}

.card.list-content {
  .big-icon {
    &:after {
      content: "";
    }
  }

  ul {
    padding-left: 1.25rem;
  }
}

.join a {
  text-decoration: underline;
}

@include breakpoint(small only) {
  .article-item-icon {
    @include breakpoint(small only) {
      padding-top: 0rem;
    }

    padding-top: 0.75rem;
  }
}

.select-language {
  li {
    padding: 0.5rem;
    font-size: 0.8rem;
  }
}

#content .grid-x.intro .cell p:last-child {
  margin-bottom: 0 !important;
}

button[data-remodal-target].dropdown {
  top: 40%;
  width: 30px;
  background: transparent;

  &:before {
    content: "\e315" !important;
    transform: rotate(0deg);
    color: #12b2f9 !important;
  }
}

.mobile-canvas-trigger {
  top: 50% !important;
  width: 30px !important;
  background: transparent !important;
  transform: translateY(-50%);

  &:before {
    content: "\e315" !important;
    transform: rotate(0deg) !important;
    color: #12b2f9 !important;
  }
}

.grid-x.accordion-title {
  @include breakpoint(small only) {
    padding: 1rem;
  }
}

.accordion-title {
  .description h3 {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-right: 15px;
    cursor: pointer;

    .number-block {
      min-width: 2rem;
      max-width: 2rem;
      height: 2rem;
      font-size: 1.25rem;
      margin-right: 1rem;
      cursor: pointer !important;

      @include breakpoint(small only) {
        align-self: start;
      }
    }
  }

  &.no-content {
    padding-bottom: 2rem;

    @include breakpoint(small only) {
      padding-bottom: 1.5rem;
    }
  }
}

.list-leyout-remodal {
  padding: 1rem;
}

.remodal,
.vertical-off-canvas {
  .service-icon {
    height: 3rem;
    width: 3rem;
    margin-bottom: 1rem;
    background-repeat: no-repeat;
    background-size: contain;
  }

  h2.big-icon {
    font-weight: 600;

    // ez sehogy sem akar megjelenni, nem tudom mi az oka
    &:after {
      content: $pn-svg-stripe !important;
      display: block;
    }

    padding-bottom: 1rem;
  }
}

.skillset ul li {
  display: flex;

  &:before {
    display: block;
  }
}

.remodal,
.grid-container {
  @include breakpoint(small only) {
    .details-inner {
      padding: 1rem;
    }
  }
}

#cookieconsent_modal {
  padding: 3rem;
  padding-top: 4rem;

  p {
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

.cc-link {
  color: #00a9ff !important;
}

.photo-column img {
  max-width: 50%;
}

.off-canvas-content .off-canvas.position-left.is-transition-overlap {
  z-index: 9999;
}

body.error404 {
  #header_subpage {
    h1 {
      font-size: 40px;
      margin: 0 auto;
    }
  }
}

a[data-smooth-scroll]:not(.arrow) {
  display: block;
  margin-top: 1rem;
}

.accordion-card > .grid-x.more-content {
  @include breakpoint(small only) {
    padding-left: 2.5rem;
  }
}

.off-canvas .number-block {
  margin-bottom: 0.6rem;
}

#newsletter {
  .agree {
    display: flex;
    align-items: flex-start;
  }
}

#newsletter .agree label {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  p {
    margin-bottom: 0;
  }
}

.home-title-text {
  position: absolute;
  bottom: 0;
  margin: 0;
  left: 0;
  width: 100% !important;
  padding: 0 2rem;
}

body.front-page {
  header .background-container {
    position: relative;
    height: calc(100vh - 60px);
  }
}

@include breakpoint(small only) {
  #header_subpage h2 {
    margin-top: 25%;
  }

  body.front-page {
    header .background-container {
      height: calc(70vh - 40px);
      background-size: 100%;
    }
  }

  .home-title-text {
    bottom: 0;
  }
}

@include breakpoint(medium) {
  body:not(.front-page) {
    header {
      height: unset;
      min-height: unset;
    }
  }
}

@include breakpoint(medium only) {
  header {
    overflow: hidden;
    position: relative;
    height: calc(100vh - 40px);
  }

  .background-container {
    height: 100%;

    & > div.grid-container:first-of-type {
      height: 100%;
    }
  }

  .home-title-text {
    bottom: 0;
  }

  body.front-page {
    header .background-container {
      height: calc(100vh - 40px);
    }
  }
}

@include breakpoint(large) {
  header {
    height: calc(100vh - 60px);
  }

  .background-container > div p {
    margin-top: 40vh;
  }

  body.front-page {
    header .background-container {
      background-position-y: calc(50% + 2rem);
    }
  }
}

div.hamburger {
  z-index: 1;
}

body.off-canvas-open {
  overflow: hidden;
}

.accordion-card button {
  position: absolute;
  left: 0;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin-bottom: 0;
  transform: none;
}

.accordion button:before {
  margin-right: 0;
  content: "\e5cd";
  font-size: 2rem;
  position: absolute;
  right: 1rem;
  transform: translateY(-50%);
  display: block;
}

header .logo {
  z-index: 1;
}

.content-block {
  a.button {
    margin-top: 1rem;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}

article.author {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  span {
    margin-left: 10px;
    display: block;
    &:first-of-type {
      font-size: 1rem;
      font-weight: 700;
    }
    &:nth-child(2) {
      font-size: 13px;
      color: #aaaaaa;
    }
  }
}

#sticky-contact-helper-button {
  width: 50px;
  height: 50px;
  background: #da4f45;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 100;
  cursor: pointer;
  border-radius: 50%;

  .open {
    display: block;
    top: -27px;
    position: relative;
    text-align: center;
    font-size: 48px;
    letter-spacing: -2px;
    color: #fff;

    &:after {
      width: 150px;
      height: 300px;
      background-color: #7db9e8;
      margin: 0 25px 5px 0;
      float: left;
      -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
      clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
      -webkit-shape-outside: polygon(0% 0%, 100% 0%, 50% 100%);
      shape-outside: polygon(0% 0%, 100% 0%, 50% 100%);
    }
  }
  .close {
    display: none;
    color: #fff;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    text-shadow: 0 1px 1px white;
    transform: scaleY(0.8) translateY(-1px);
  }

  &.active {
    .open {
      display: none;
    }
    .close {
      display: flex;
    }
  }
}

#sticky-contact-helper-form {
  display: none;
  position: fixed;
  right: 30px;
  bottom: 100px;
  z-index: 100;
  box-shadow: 0px 3px 14px 6px rgba(0, 0, 0, 0.3);

  &.open {
    display: block;
    border-radius: 5px;

    &:before {
      content: "";
      background: radial-gradient(ellipse at right, #000000 0%, transparent 100%);
      width: 100%;
      height: 100%;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: -1;
      opacity: 0.5;
    }

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      right: 22px;
      box-sizing: border-box;
      border: 0.45em solid black;
      border-color: transparent transparent #fff #fff;

      transform-origin: 0 0;
      transform: rotate(-45deg);

      box-shadow: 0px 3px 14px 6px rgba(189, 189, 189, 0.7);
      z-index: -1;
    }
  }

  #form-sticky-selected-service {
    display: none;
  }

  #form-sticky {
    padding: 0;
    border-radius: 5px;
    &.on-page-2 {
      padding: 0;
      background-color: #fff;

      #form-sticky-selected-service {
        display: block;
      }
    }

    .page-2 {
      h3 {
        position: relative;
        padding-left: 40px;
        cursor: pointer;
        &:before {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 7px 5px 0;
          border-color: transparent #da4f45 transparent transparent;
          content: "";
          left: 1.5rem;
          position: absolute;
          top: 26px;
        }
      }
    }
  }

  #form-sticky-selected-service {
    color: #0a1d48;
  }

  .sticky-title {
    font-weight: 900;
    font-size: 1.3rem;
    text-align: left;
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  p {
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
    padding: 0rem 1.5rem;
    line-height: 1.2rem;
  }

  .direct-email {
    background: #fff;
    border-top: 1px solid #d8d8d8;
    padding: 1rem 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .dropdown-option {
      text-align: left;
      position: relative;

      &:hover {
        background-color: #e0e1e6;
      }

      &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 7px;
        border-color: transparent transparent transparent #0eaaff;
        content: "";
        right: 1.5rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    a {
      display: inline-block;
      padding: 0.5rem 1.5rem;
      width: 100%;
    }
  }
  .services {
    background-color: #fff;
    text-align: left;
    color: #0a1d48;
    font-weight: 900;
    padding: 1.2rem 0rem;

    .dropdown-option {
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: #e0e1e6;
      }

      &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 7px;
        border-color: transparent transparent transparent #da4f45;
        content: "";
        right: 1.5rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    span {
      display: inline-block;
      padding: 0.5rem 1.5rem;
    }
  }

  .ffl-wrapper {
    input {
      background-color: #e0e1e6;
      font-size: 13px;
    }

    button {
      padding: 0.2rem 2rem !important;
      line-height: 2.1rem;
    }
  }

  .contact-sticky {
    padding: 0rem 1.5rem;

    .dropdown {
      background-color: #e0e1e6;
    }

    .agree {
      margin: 0px;
      label {
        &:before {
          background-color: #f0f0f0;
          border-radius: 0;
        }
        p {
          color: #000;
          font-size: 11px;
          margin-left: 1rem;
          margin-top: 0px;
          line-height: 17px;
        }
      }
    }

    .button {
      font-size: 14px;
      text-transform: capitalize;
      width: 100%;
      margin-bottom: 20px;
      display: inline-block;
      padding-left: 0.7rem;
    }

    .button.dropdown {
      padding-left: 0.7rem;
    }
  }
}

#industry-dropdown-sticky {
  box-shadow: none;
  display: block !important;
  height: 0px !important;
  position: relative;
  transition: height 0.2s ease-out;

  &.is-open {
    height: 13rem !important;
    position: relative;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    margin-bottom: 1rem;
    border-bottom: 1px solid #d8d8d8;
    padding: 1rem 0;
    padding-top: 0;
  }
  .dropdown-option:last-child {
    margin-bottom: 0px !important;
  }
}

#form-sticky {
  .pages {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    transition: height 0.2s ease-in-out;

    &.on-page-2 {
      .page-1 {
        left: 0;
        opacity: 0;
      }
      .page-2 {
        opacity: 1;
        left: 0;
        * {
          pointer-events: all !important;
        }
      }
    }

    > div {
      width: 320px;
      transition: 0.2s ease-in-out;
    }

    .page-1 {
      opacity: 1;
    }

    .page-2 {
      opacity: 0;
      position: absolute;
      background: white;
      left: 300px;
      bottom: 0;
      * {
        pointer-events: none !important;
      }
      border-radius: 5px;
      overflow: hidden;
    }
  }
  form {
    height: unset;
  }
}
