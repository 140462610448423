@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.remodal-overlay {
  background: $pn-modal-overlay-background;

  &.remodal-is-opening {
    animation: fadein $pn-modal-animation-duration;
  }

  &.remodal-is-closing {
    animation: fadein reverse $pn-modal-animation-duration;
  }
}

.remodal-wrapper {
  display: flex;
  text-align: left;

  @include breakpoint(medium down) {
    @include flex-align(center, top);
  }

  @include breakpoint(large) {
    @include flex-align(center, middle);
  }
}

.remodal,
.vertical-off-canvas {
  background: $pn-modal-background;

  @include breakpoint(small only) {
    width: 100vw;
    min-height: 100vh;

    [class^=grid-] {
      position: relative;
    }
  }

  @include breakpoint(medium only) {
    width: map-get($breakpoints, medium);
    min-height: 100vh;
  }

  @include breakpoint(large) {
    width: $global-width;
    min-height: $pn-modal-min-height-large;
    max-height: 100vh;
    overflow: auto;
  }

  .list-content {
    padding: 1rem;
    box-shadow: none !important;
    background: #fff !important;

    &:after {
      display: none !important;
    }

    h2 {
      margin-top: 0;
      padding-left: 0;
    }
  }

  &.remodal-is-opening {
    animation: fadein $pn-modal-animation-duration;
  }

  &.remodal-is-closing {
    animation: fadein reverse $pn-modal-animation-duration;
  }
}

.remodal-cancel {
  @extend .hamburger;

  border-color: $pn-modal-close-background;
  background: $pn-modal-close-background !important;
  color: $pn-modal-close-color !important;
  opacity: 1;

  @include breakpoint(small only) {
    top: .25rem;
    right: 1.25rem;
  }

  @include breakpoint(medium only) {
    top: $pn-modal-close-top-medium;
    right: $pn-modal-close-right-medium;
  }

  @include breakpoint(large) {
    top: $pn-modal-close-top-large;
    right: $pn-modal-close-right-large;
  }

  &::after {
    content: "\e5cd";
  }
}