body.pattern-kms {

    // lista jobbra nyilakkal
    .list-with-subcontent {
        margin-bottom: 2rem;

        .card {
            width: 99%;
            cursor: pointer;
            position: relative;
            margin-bottom: .75rem;

            &:after {
                pointer-events: none;
            }

            a {
                color: #0A1D48;
                outline: 0;
                padding: .8rem 4rem .8rem 1rem;

                :focus {
                    outline: 0;
                }
            }

            &.is-active {
                cursor: default;
                font-weight: bold;
                background: #f0f0f0;
                box-shadow: 0 0 0 3px #dfe0e5 inset;

                &:after {
                    color: #042351;
                }
            }

            &:hover:not(.active) {
                width: 100%;
                font-weight: bold;

                &:after {
                    color: #f91d04;
                }
            }

            &:after {
                font-family: 'Material Icons';
                content: '\e315';
                right: .3rem;
                top: 50%;
                transform: translateY(-50%);
                font-size: 1.8rem;
                position: absolute;
                line-height: 2.8rem;
            }

            @include breakpoint (small) {
                &:after {
                    color: #1bb0fe;
                }
            }

            @include breakpoint (large) {
                &:after {
                    color: #e2e3e8;
                }
            }
        }

        &.blue {
            .card {
                &:hover:not(.active) {
                    &:after {
                        color: rgb(11, 169, 255);
                    }
                }
            }
        }
    }



    .list-content {

        display: none;

        .content-wrap {
            padding: 2rem;
        }

        &.is-active {
            display: block;
        }
    }

    .percentages {
        border-top: 3px solid #f0f0f0;
        padding: 3rem;

        .title {
            color: #e24d3f;
            font-size: 2.5rem;
        }

        .description {
            font-size: .9rem;
        }
    }

    .vertical-off-canvas {
        .percentages::after {
            display: none;
        }

        .card {
            &:hover:not(.active) {
                width: 99%;
                font-weight: inherit;
            }
        }
    }


    .small-cards {
        justify-content: space-between;

        .card {
            margin-bottom: 2rem;
            padding: 1.5rem 2rem;

            @include breakpoint (small only) {
                padding: 1.25rem;

                .small-icon {
                    padding-left: 2.5rem;
                    padding-right: 2rem;
                }

                margin-bottom: .5rem;
            }
        }
    }

}

body.pattern-kms {
    #header_subpage {
        h2 {
            @include breakpoint (small only) {
                margin-top: 14rem;
                width: 50%;
                line-height: 1.5rem;
            }
        }

        p {
            @include breakpoint (small only) {
                font-size: .75rem;
                line-height: 1rem;
            }
        }
    }
}


#tangible_benefits,
#covered_dapabilities,
#howcan_pattern_helpyou,
.page-section {
    padding-top: 2rem;
    border-top: 3px solid #eeeeee;
    margin-top: 3rem;

    &:first-of-type {
        padding-top: 0;
        border-top: none;
        //     margin-top: 0;
    }
}