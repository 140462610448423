.hamburger {
  $offset-adjusted: $pn-header-logo-offset-small - $pn-hamburger-button-border-width;

  position: absolute;
  top: $offset-adjusted;
  right: $offset-adjusted;
  width: $pn-hamburger-button-width;
  height: $pn-hamburger-button-width;
  border: $pn-hamburger-button-border-width solid $pn-hamburger-button-border-color;
  border-radius: $pn-hamburger-button-border-radius;
  font-family: "Material Icons";
  color: $pn-hamburger-button-color;
  cursor: pointer;

  &:focus {
    outline: 0 !important;
    -webkit-tap-highlight-color: transparent;
  }
  &::after {
    content: "\e5d2";
    display: block;
    line-height: $pn-hamburger-button-width - $pn-hamburger-button-border-width * 2;
    text-align: center;
    font-size: $pn-hamburger-button-font-size;
  }

  &.close {
    color: $pn-hamburger-button-close-color;
    opacity: $pn-hamburger-button-close-opacity;

    &::after {
      content: "\e5cd";
    }
  }
}

#hamburger-menu {
  h3 {
    margin-top: $pn-hamburger-menu-h3-margin-top;
    margin-bottom: $pn-hamburger-menu-h3-margin-bottom;
    font-size: $pn-hamburger-menu-h3-font-size;
    font-weight: $pn-hamburger-menu-h3-font-weight;
    color: $pn-hamburger-menu-h3-color;
  }
  a {
    width: 100%;

    &,
    &:hover,
    &:active,
    &:focus {
      color: $pn-color-main;
    }
    &:not(:first-of-type) {
      margin-top: $pn-hamburger-menu-item-gap;
    }
  }
  .cell {
    padding: 0 $pn-grid-container-padding-small;
  }
  .item {
    height: $pn-hamburger-menu-item-height;
    line-height: $pn-hamburger-menu-item-height;
    background: $pn-hamburger-menu-item-background;
    font-size: $pn-hamburger-menu-item-font-size;

    &::after {
      content: "\e315";
      position: absolute;
      right: rem-calc(9px);
      line-height: $pn-hamburger-menu-item-height;
      font-family: "Material Icons";
      font-size: $pn-hamburger-menu-item-icon-font-size;
      color: $pn-hamburger-menu-item-icon-color;
    }
  }
}

.single-post {
  .hamburger::after,
  .remodal-cancel::after {
    color: #0a1e47;
  }
}
