footer {
  background: $pn-footer-background;
  font-size: $pn-footer-font-size;

  @include breakpoint(small only) {
    padding: $pn-footer-padding-small 0;
    text-align: center;

    a {
      display: inline-block;
      margin-bottom: $pn-footer-font-size;
    }
    .notice {
      margin-top: $pn-footer-font-size;
    }
  }
  @include breakpoint(medium only) {
    height: $pn-footer-height-medium;
    line-height: $pn-footer-height-medium;

    a {
      margin-left: $pn-link-margin-left-medium;
    }
  }
  @include breakpoint(large) {
    height: $pn-footer-height-large;
    padding-top: $pn-footer-font-size;

    a {
      margin-left: $pn-link-margin-left-large;
    }
  }

  &,
  a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: $pn-footer-color;
  }
  .links {
    text-align: right;
  }
}
