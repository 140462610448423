header {
  text-align: center;
  background: $pn-header-background;

  ul.select-language {
    position: absolute;
    right: 20px;
    top: 20px;
    text-transform: uppercase;

    @include breakpoint(small only) {
      right: 70px;
    }

    li {
      display: inline-block;

      a {
        color: #fff;
      }

      &.current-lang a {
        color: #00a9ff;
      }
    }
  }

  .logo {
    a {
      position: absolute;
      width: 100%;
      height: 100%;
      display: inline-block;
      content: "";
      left: 0;
      top: 0;
    }

    content: "";
    position: absolute;
    background-image: $pn-svg-logo;

    @include breakpoint(small only) {
      top: $pn-header-logo-offset-small;
      left: $pn-header-logo-offset-small;
      width: $pn-header-logo-width-small;
      height: $pn-header-logo-height-small;
      background-size: $pn-header-logo-width-small $pn-header-logo-height-small;
    }

    @include breakpoint(medium only) {
      top: $pn-header-logo-offset-medium;
      left: $pn-header-logo-offset-medium;
      width: $pn-header-logo-width-medium;
      height: $pn-header-logo-height-medium;
      background-size: $pn-header-logo-width-medium $pn-header-logo-height-medium;
    }

    @include breakpoint(large) {
      top: $pn-header-logo-offset-large;
      left: $pn-header-logo-offset-large;
      width: $pn-header-logo-width-large;
      height: $pn-header-logo-height-large;
      background-size: $pn-header-logo-width-large $pn-header-logo-height-large;
      top: 2.5rem;
      left: 2.5rem;
      width: 3.1rem;
      height: 5.6875rem;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  h1,
  p {
    color: $pn-header-color-primary;
    margin-bottom: 1em;
  }

  h1 {
    @include breakpoint(small only) {
      margin-top: $pn-header-h1-margin-top-small;
    }
  }

  p {
    color: $pn-header-color-secondary;
    font-size: inherit;

    @include breakpoint(small only) {
      line-height: $pn-header-p-line-height-small;
    }

    @include breakpoint(medium only) {
      line-height: $pn-header-p-line-height-medium;
    }

    @include breakpoint(large) {
      line-height: $pn-header-p-line-height-large;
    }

    &:first-of-type {
      @include breakpoint(small only) {
        //margin-top: $pn-header-p-first-margin-top-small;
      }

      @include breakpoint(medium only) {
        // margin-top: $pn-header-p-first-margin-top-medium;
      }

      @include breakpoint(large) {
        // margin-top: $pn-header-p-first-margin-top-large;
      }
    }

    &:last-of-type {
      @include breakpoint(small only) {
        margin-bottom: $pn-header-p-last-margin-bottom-small;
      }

      @include breakpoint(medium only) {
        margin-bottom: $pn-header-p-last-margin-bottom-medium;
      }

      @include breakpoint(large) {
        margin-bottom: 1.6rem;
      }
    }
  }

  .background-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;

    @include breakpoint(small only) {
      background-image: $pn-svg-header-small;
      background-position-y: calc(50% + 1rem);
    }

    @include breakpoint(medium only) {
      background-image: $pn-svg-header-medium;
    }

    @include breakpoint(large) {
      background-image: $pn-svg-header-large;
    }
  }

  .menu {
    margin-top: $pn-header-menu-margin-top;
  }

  .dropdown-pane {
    @include breakpoint(medium only) {
      font-size: $pn-button-header-font-size-medium;
    }

    @include breakpoint(large) {
      font-size: $pn-button-header-font-size-large;
    }
  }

  .arrow {
    display: inline-block;
    font-family: "Material Icons";
    color: $pn-header-arrow-color;
    // margin-top: 2em;
    margin-bottom: 3em; //felette lévő p-nek van 1em margin top, összesen így 3em
    font-size: 1.25em;
  }
}

body.consulting .background-container {
  background-image: url("../images/header_consulting-large.jpg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto 100%;

  @include breakpoint(small only) {
    background-image: url("../images/header_consulting-small.jpg");
    background-position: center;
  }

  @include breakpoint(medium only) {
    background-image: url("../images/header_consulting-medium.jpg");
  }
}

body.kms .background-container {
  background-image: url("../images/header_kms-large.jpg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto 100%;

  @include breakpoint(small only) {
    background-image: url("../images/header_kms-small.jpg");
    background-position: center;
  }

  @include breakpoint(medium only) {
    background-image: url("../images/header_kms-medium.jpg");
  }
}

body.mat .background-container {
  background-image: url("../images/header_mat-large.jpg");
  background-position: right;
  background-repeat: no-repeat;

  @include breakpoint(small only) {
    background-image: url("../images/header_mat-small.jpg");
    background-position: center;
  }

  @include breakpoint(medium only) {
    background-image: url("../images/header_mat-medium.jpg");
  }
}

.single-post {
  header ul.select-language li a {
    color: #9e9e9e;
  }
  header ul.select-language li.current-lang a {
    color: #00a9ff;
  }
}
