#mc-embedded-subscribe-form {
  input:not([type="submit"]),
  select {
    border-radius: 2px;
    padding: 0.8rem;
  }
  select {
    padding-right: 2rem;
    height: unset;
  }
  label {
    font-size: 1.2rem;
    color: #1c2e55;
  }
  .error-msg {
    display: none;
  }
  .invalid {
    input {
      box-shadow: 0 0 0 2px red;
    }
    .error-msg {
      display: block;
      margin-top: -1rem;
      margin-bottom: 1rem;
      color: red;
    }
  }
  .ma-fields {
    display: flex;
    & > div {
      flex: 1;
      &:first-child {
        margin-right: 1rem;
      }
    }
  }
}

.page-template-page_ma-landing {
  header.ma-landing {
    #header_subpage {
      h2 {
        display: flex;
        text-align: center;
        width: 85%;
        margin: 3rem auto;
        margin-bottom: 4rem;
        justify-content: center;
        &:after {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .logo {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  section.ma-landing {
    .content-cell {
      margin: 0 auto;
      margin-top: 2rem;
      p {
        font-size: 17px;
      }
    }
    h2 {
      margin-bottom: 4rem;
      padding-bottom: 7rem;
    }
    ul li {
      padding-bottom: 0.5rem;
    }
  }
}

#mc_embed_signup {
  margin-bottom: 3rem;
  margin-top: 3rem;
  padding-left: 2rem;
  border-left: 0.5rem solid #e5e6e9;
  display: block;
  input[type="submit"] {
    background: #e24c3d;
    &:hover {
      background: #b7382f;
    }
  }
}

.download-hash {
  font-size: 17px;
}

.dl-tanulmany-outer {
  text-align: center;
  margin-bottom: 3rem;
  a {
    margin: 0 auto;
    padding: 0.5rem 1.5rem;
    background: #e24c3d;
    &:hover {
      background: #b7382f;
    }
  }
}

#tanulmany {
  font-size: 17px;
  font-weight: bold;
  padding-top: 2rem;
}
