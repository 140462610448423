#contact {
  line-height: 1;
  background: $pn-contact-background;
  text-align: center;
  padding-top: 0;

  .method {
    background: $pn-background;

    @include breakpoint(small only) {
      padding: $pn-contact-method-padding-small;

      &:first-child {
        margin-top: $pn-contact-method-first-margin-top-small;
        margin-bottom: $pn-contact-method-first-margin-bottom-small;
      }
    }

    @include breakpoint(medium only) {
      padding: $pn-contact-method-padding-medium;
    }

    @include breakpoint(large) {
      margin-top: $pn-contact-method-margin-top-large;
      padding: $pn-contact-method-padding-large;
    }
  }

  .label {
    display: inline-block;
    position: relative;

    @include breakpoint(small only) {
      margin-bottom: $pn-contact-label-margin-bottom-small;
      line-height: $pn-contact-label-line-height-small;
      font-size: $pn-contact-label-font-size-small;
    }

    @include breakpoint(medium only) {
      margin-bottom: $pn-contact-label-margin-bottom-medium;
      line-height: $pn-contact-label-line-height-medium;
      font-size: $pn-contact-label-font-size-medium;
    }

    @include breakpoint(large) {
      margin-bottom: $pn-contact-label-margin-bottom-large;
      line-height: $pn-contact-label-line-height-large;
      font-size: $pn-contact-label-font-size-large;
    }

    &::before {
      position: absolute;
      top: 0;

      @include breakpoint(small only) {
        height: $pn-contact-label-line-height-small;
      }

      @include breakpoint(medium) {
        height: $pn-contact-label-line-height-medium;
      }
    }

    &.email {
      &::before {
        @include breakpoint(small only) {
          content: $pn-svg-email-narrow;
          left: rem-calc(-27px);
        }

        @include breakpoint(medium) {
          content: $pn-svg-email;
          left: rem-calc(-33px);
        }
      }
    }

    &.phone {
      &::before {
        @include breakpoint(small only) {
          content: $pn-svg-phone-narrow;
          left: rem-calc(-24px);
        }

        @include breakpoint(medium) {
          content: $pn-svg-phone;
          left: rem-calc(-30px);
        }
      }
    }
  }

  .detail {

    &,
    >a {
      color: $pn-contact-detail-color;
    }

    >a {

      &:hover,
      &:active,
      &:focus {
        color: $pn-contact-detail-color-hover;
      }
    }

    @include breakpoint(small only) {
      font-size: $pn-contact-detail-font-size-small;
    }

    @include breakpoint(medium only) {
      font-size: $pn-contact-detail-font-size-medium;
    }

    @include breakpoint(large) {
      font-size: $pn-contact-detail-font-size-large;
    }
  }

  .social {
    @include breakpoint(small only) {
      margin-top: $pn-contact-social-margin-top-small;
      margin-bottom: $pn-contact-social-margin-bottom-small;
    }

    @include breakpoint(medium only) {
      margin-top: $pn-contact-social-margin-top-medium;
      margin-bottom: $pn-contact-social-margin-bottom-medium;
    }

    @include breakpoint(large) {
      margin-top: $pn-contact-social-margin-top-large;
      margin-bottom: $pn-contact-social-margin-bottom-large;
    }
  }

  .ortext {
    margin-right: $pn-contact-ortext-margin-right;
    font-size: $pn-contact-ortext-font-size;
    color: $pn-contact-ortext-color;

    @include breakpoint(small only) {
      line-height: $pn-contact-social-icon-width-small;
    }

    @include breakpoint(medium only) {
      line-height: $pn-contact-social-icon-width-medium;
    }

    @include breakpoint(large) {
      line-height: $pn-contact-social-icon-width-large;
    }
  }

  .icons {
    display: inline-block;
    vertical-align: bottom;
    font-size: 0;

    @include breakpoint(small only) {
      height: $pn-contact-social-icon-width-small;
    }

    @include breakpoint(medium only) {
      height: $pn-contact-social-icon-width-medium;
    }

    @include breakpoint(large) {
      height: $pn-contact-social-icon-width-large;
    }

    >a {
      display: inline-block;

      @include breakpoint(small only) {
        margin-right: $pn-contact-social-icon-margin-small;
      }

      @include breakpoint(medium only) {
        margin-right: $pn-contact-social-icon-margin-medium;
      }

      @include breakpoint(large) {
        margin-right: $pn-contact-social-icon-margin-large;
      }

      &,
      .social-icon {
        @include breakpoint(small only) {
          width: $pn-contact-social-icon-width-small;
          height: $pn-contact-social-icon-width-small;
        }

        @include breakpoint(medium only) {
          width: $pn-contact-social-icon-width-medium;
          height: $pn-contact-social-icon-width-medium;
        }

        @include breakpoint(large) {
          width: $pn-contact-social-icon-width-large;
          height: $pn-contact-social-icon-width-large;
        }
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}