.content {
  .intro {
    p {
      @include breakpoint(small only) {
        max-width: $pn-content-p-max-width-small;
      }

      @include breakpoint(medium only) {
        max-width: $pn-content-p-max-width-medium;
      }

      &:last-of-type {
        @include breakpoint(small only) {
          //margin-bottom: $pn-content-p-margin-bottom-small;
        }

        @include breakpoint(medium only) {
          //margin-bottom: $pn-content-p-margin-bottom-medium;
        }

        @include breakpoint(large) {
          margin-bottom: $pn-content-p-margin-bottom-large;
        }
      }
    }
  }

  .card {
    &:not(:last-of-type) {
      @include breakpoint(small only) {
        margin-bottom: $pn-content-card-margin-bottom-small;
      }

      @include breakpoint(medium only) {
        margin-bottom: $pn-content-card-margin-bottom-medium;
      }

      @include breakpoint(large) {
        margin-bottom: $pn-content-card-margin-bottom-large;
      }
    }

    &.last {
      @include breakpoint(medium down) {
        margin-bottom: 0;
      }
    }

    >[class^=grid-] {
      @include breakpoint(small only) {

        line-height: 1;
        padding: $pn-content-card-padding-small;
      }

      @include breakpoint(medium only) {
        height: $pn-content-card-height-medium;
        padding: 0 $pn-content-card-padding-right-medium 0 $pn-content-card-padding-left-medium;
      }

      @include breakpoint(large) {
        height: $pn-content-card-height-large;
        padding: 0 $pn-content-card-padding-right-large 0 $pn-content-card-padding-left-large;
      }
    }
  }

  .description,
  .action {
    @include breakpoint(medium) {
      @include flex-align-self(middle);
    }
  }

  .description {}

  .article {
    .description {}

    .article-item-icon {
      position: relative;
      float: left;
      height: 100%;

      @include breakpoint(small only) {
        @include flex-align-self(middle);
        width: $pn-content-icon-width-small;
        height: $pn-content-icon-height-small;
        margin-right: $pn-content-icon-margin-right-small;
      }

      @include breakpoint(medium only) {
        @include flex-align-self(middle);
        width: $pn-content-icon-width-medium;
        height: $pn-content-icon-height-medium;
        margin-right: $pn-content-icon-margin-right-medium;
      }

      @include breakpoint(large) {
        @include flex-align-self(middle);
        width: $pn-content-icon-width-large;
        height: $pn-content-icon-height-large;
        margin-right: $pn-content-icon-margin-right-large;
      }

      >a {
        font-weight: $pn-content-action-font-weight;
        font-size: $pn-content-action-font-size;
      }
    }
  }

  .video {
    .description {
      &::before {
        content: $pn-svg-video;

        @include breakpoint(medium only) {
          content: $pn-svg-video-narrow;
        }
      }
    }
  }

  .file {
    .description {
      &::before {
        content: $pn-svg-file;

        @include breakpoint(medium only) {
          content: $pn-svg-file-narrow;
        }
      }
    }
  }

  .event {
    .description {
      &::before {
        content: $pn-svg-event;

        @include breakpoint(medium only) {
          content: $pn-svg-event-narrow;
        }
      }
    }
  }

  .action {
    text-align: right;
  }

  .button {
    margin: 0;
  }

  .title {
    font-weight: $pn-content-title-font-weight;

    @include breakpoint(small only) {
      margin-bottom: $pn-content-title-margin-bottom-small;
      line-height: $pn-content-title-line-height-small;
      font-size: $pn-content-title-font-size-small;
    }

    @include breakpoint(medium only) {
      font-size: $pn-content-title-font-size-medium;
    }

    @include breakpoint(large) {
      font-size: $pn-content-title-font-size-large;
    }
  }

  .date {
    line-height: $pn-content-date-line-height;
    font-size: $pn-content-date-font-size;
    color: $pn-content-date-color;
  }

  .more {
    margin-top: $pn-content-button-margin-top - $pn-content-card-margin-bottom-large;
    text-align: center;
  }
}