.grid-container {
  @include breakpoint(small only) {
    max-width: $pn-grid-container-max-width-small;
  }
  @include breakpoint(medium only) {
    max-width: $pn-grid-container-max-width-medium + 2 * $pn-indent-medium;
  }
}

.full-width {
  @at-root nav#{&},
    section#{&},
    footer#{&} {
    @include breakpoint(medium only) {
      padding: 0 $pn-indent-medium;
    }

    .grid-container {
      @include breakpoint(medium only) {
        max-width: $pn-grid-container-max-width-medium;
      }
    }
  }

  &.framed {
    @include breakpoint(medium) {
      border-top: $pn-framed-border;
      border-bottom: $pn-framed-border;
    }
  }
}

.shapeshifter {
  height: 100%;

  section & {
    @include breakpoint(medium down) {
      display: none;
    }
  }
  .off-canvas-content > & {
    @include breakpoint(large) {
      display: none;
    }
  }

  > section {
    @mixin flex-center {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &.box {
      height: 100%;

      > [class^="grid-"] {
        @include flex-center;
      }
    }
    &:not(.box) {
      @include flex-center;
    }
  }

  @at-root .shapeshifter-container {
    flex-grow: 1;
    padding: 0 !important;
  }
}

.box {
  @include breakpoint(small only) {
    padding: $pn-box-padding-small 0;
    background: $pn-box-background;
    box-shadow: $pn-box-shadow;

    &.box-dim {
      background: $pn-box-dim-background;
      box-shadow: none;
    }
    .content-block {
      padding-left: rem-calc($pn-grid-container-padding-small);
      padding-right: rem-calc($pn-grid-container-padding-small);
    }
    .last {
      margin-bottom: 0 !important;
    }
  }

  @include breakpoint(medium) {
    > [class^="grid-"] {
      background: $pn-box-background;
      box-shadow: $pn-box-shadow;
    }

    &.box-dim {
      > [class^="grid-"] {
        background: $pn-box-dim-background;
        box-shadow: none;
      }
    }
  }

  @include breakpoint(medium only) {
    > [class^="grid-"] {
      margin: 0 $pn-box-margin-medium !important;
      padding: $pn-box-padding-medium 0;
    }

    &.box-dim {
      > [class^="grid-"] {
        padding: $pn-box-dim-padding-medium;
      }
    }
  }

  @include breakpoint(large) {
    > [class^="grid-"] {
      margin: $pn-box-margin-large !important;
      padding: $pn-box-padding-large 0;
    }

    &.box-dim {
      > [class^="grid-"] {
        padding: $pn-box-dim-padding-large;
      }
    }
  }

  .pictogram-container {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: $pn-box-pictogram-width;
      height: $pn-box-pictogram-width;

      @include show-for(medium);

      @include breakpoint(medium only) {
        left: $pn-box-padding-medium;
      }
      @include breakpoint(large) {
        left: $pn-box-padding-large;
      }
    }
  }
  .text,
  .subsections {
    > .cell:first-child {
      @include breakpoint(medium) {
        padding-left: 0;
      }
    }
  }
  .text {
    p {
      @include breakpoint(large) {
        font-size: $pn-solutions-subsection-p-font-size-large;
      }
    }
  }
  .button {
    // @include breakpoint(small only) {
    //   margin: $pn-box-button-margin-top-small 0 $pn-box-button-margin-bottom-small;
    // }
    // @include breakpoint(medium only) {
    //   margin: $pn-box-button-margin-top-medium 0 $pn-box-button-margin-bottom-medium;
    // }
    // @include breakpoint(large) {
    //   margin: $pn-box-button-margin-top-large 0 $pn-box-button-margin-bottom-large;
    // }
  }
  &.box-dim {
    h2 {
      font-size: $pn-box-dim-h2-font-size;

      @include breakpoint(medium only) {
        margin-bottom: $pn-box-dim-h2-margin-bottom;
      }
    }
    > [class^="grid-"] {
      border: $pn-box-dim-border;
    }
  }
}

.card {
  section & {
    flex-grow: 0;
  }
}

.card,
.shadow {
  box-shadow: $pn-box-shadow;

  &.modest {
    box-shadow: $pn-box-shadow-modest;
  }
}
