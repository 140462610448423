.button {
  @include breakpoint(small only) {
    line-height: $pn-button-line-height-small;
    font-size: $pn-button-font-size-small;
  }

  @include breakpoint(medium only) {
    line-height: $pn-button-line-height-medium;
    font-size: $pn-button-font-size-medium;
  }

  @include breakpoint(large) {
    line-height: 2rem;
    font-size: $pn-button-font-size-large;
  }

  width: auto;
  display: inline-block;
  padding: 0 1rem;

  &,
  &.default {
    border-width: 0 !important;
    border-radius: $pn-button-radius;

    &,
    &.primary {
      background: $pn-button-primary-background;
      color: $pn-button-primary-color;

      &:hover,
      &:active,
      &:focus {
        background: $pn-button-primary-background-hover;
      }
    }

    &.secondary {
      background: $pn-button-secondary-background;

      &:hover,
      &:active,
      &:focus {
        background: $pn-button-secondary-background-hover;
      }
    }

    &.clear {
      font-weight: $pn-button-clear-font-weight;
      color: $pn-button-clear-color;

      @include breakpoint(small only) {
        font-size: $pn-button-clear-font-size-small;
      }

      @include breakpoint(medium only) {
        font-size: $pn-button-clear-font-size-medium;
      }

      @include breakpoint(large) {
        width: $pn-button-clear-width-large;
        line-height: $pn-button-clear-line-height-large;
        font-size: $pn-button-clear-font-size-large;
      }

      &:hover,
      &:active,
      &:focus {
        background: $pn-button-clear-background-hover;
        color: $pn-button-clear-color;
      }
    }

    &.success {
      &,
      &:hover,
      &:active,
      &:focus {
        background: $pn-button-success-background;
        color: $pn-button-success-color;
      }

      > span {
        display: inline-block;
        position: relative;

        &::before {
          content: "\e5ca";
          position: relative;
          top: rem-calc(1px);
          left: rem-calc(-5px);
          float: left;
          font-family: "Material Icons";
          font-weight: normal;
        }
      }
    }

    &.dropdown {
      &::after {
        content: "\e5c5";
        position: relative;
        top: rem-calc(2px);
        float: none;
        margin-left: rem-calc(5px);
        padding-right: rem-calc(10px);
        border: none !important;
        font-family: "Material Icons";
      }

      &.expanded {
        padding-left: $pn-button-dropdown-expanded-padding-left;
        text-align: left;
        font-size: $pn-button-dropdown-expanded-font-size;
        outline: none !important;

        &::after {
          right: $pn-button-dropdown-expanded-padding-left;
          float: right;
          font-size: $pn-button-dropdown-chevron-font-size;
        }

        &.formfield {
          padding-left: $pn-input-padding-sides;
          background: $input-background;
          color: $input-placeholder-color;

          &.done,
          &::after {
            color: $input-color;
          }
        }

        &.hover {
          &::after {
            content: "\e5c7";
          }
        }

        > span {
          display: inline-block;
          max-width: 85%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &.done {
        background: $pn-button-dropdown-expanded-done-background;
      }
    }
  }

  header & {
    &,
    &.primary {
      background: $pn-button-header-background;
      color: $pn-button-header-color;

      &:hover,
      &:active,
      &:focus {
        background: $pn-button-header-background-hover;
      }
    }

    @include breakpoint(medium only) {
      //width: $pn-button-header-width-medium;
      line-height: $pn-button-header-line-height-medium;
      margin-bottom: $pn-button-header-margin-bottom-medium;
      font-size: $pn-button-header-font-size-medium;
    }

    @include breakpoint(large) {
      //width: $pn-button-header-width-large;
      line-height: $pn-button-header-line-height-large;
      margin-bottom: $pn-button-header-margin-bottom-large;
      margin-bottom: 4rem;
      font-size: $pn-button-header-font-size-large;
    }

    &.dropdown {
      &:hover,
      &:active,
      &:focus {
        background: $pn-button-header-background;
      }

      &::after {
        top: rem-calc(4px);
        font-size: $pn-button-header-font-size-large;
        color: $pn-button-dropdown-header-chevron-color;
      }
    }
  }
}
