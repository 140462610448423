.can-we-help-box{
    position: fixed;
    bottom: -100%;
    left: 50%;
    transform: translateX(-50%);
    background-color:#fff;
    box-shadow: 0px 3px 14px 6px rgba(189,189,189,0.70);
    z-index: 100;
    border-radius: 5px;
    padding-right: 20px;
    transition: all 1s;

    &.active{
        bottom:20px;
    }

    .close{
        position:absolute;
        right:20px;
        top:15px;
        cursor:pointer;
    }

    .picture img{
        border-radius: 5px;
    }

    span.title{
        text-transform: initial;
        font-size: 1.25rem;
        line-height: 1.5;
        margin-bottom: 0.2rem;
        font-weight: 900;
        display: inline-block;
        margin-top: 20px;
    }

    p{
        font-size: 0.8rem;
    }

    .form-input-wrapper{
        input{
            background-color:#e0e1e6;
            font-size: 13px;
            height: 2.5rem !important;
        }

        button{
            padding: 0.2rem 2rem !important;
            line-height: 2.1rem;
        }
    }

    .agree{
        display: flex;
        align-items: flex-start;
        position:relative;
        padding-bottom: 20px;
        label {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            width: 100%;
            margin: 0;

            &::before {
                margin-right: 1rem;
                content: "";
                display: flex;
                float: left;
                font-family: "Material Icons";
                font-size: $pn-form-agree-checkbox-font-size;
                background: #e0e1e6;
                color: $pn-form-agree-checkbox-color;
                min-width: 1.875rem;
                min-height: 1.875rem;
        
                @include flex-align(center, middle);
        
                @include breakpoint(small only) {
                  width: $pn-form-agree-checkbox-width-small;
                  height: $pn-form-agree-checkbox-width-small;
                }
        
                @include breakpoint(medium only) {
                  width: $pn-form-agree-checkbox-width-medium;
                  height: $pn-form-agree-checkbox-width-medium;
                }
        
                @include breakpoint(large) {
                  width: $pn-form-agree-checkbox-width-large;
                  height: $pn-form-agree-checkbox-width-large;
                }
            }
          
            p {
              margin-bottom: 0;
            }
        }

        [type="checkbox"] {
            position: absolute;
            height: auto !important;
            margin: 0;
            opacity: 0;
            cursor: pointer;
      
            &:checked {
                + label {
                &::before {
                    content: "\e5ca";
                    background: #FFC500;
                }
                }
            }
        }
    }

    .help_form_response{
        
        &-error{
            color:#E24C3E;
            font-size: 16px;
            font-weight: 900;
        }
        &-success{
            color: #28bb10;
            font-size: 16px;
            font-weight: 900;
        }
    }
}