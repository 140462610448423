$experience-box-title-color: #e24d3f;

// EXPERIENCE
.experience {
  .grid-container {
    @include breakpoint(medium) {
      padding: 0;
    }

    @include breakpoint(large) {
      padding: 0;
    }

    position: relative;
    background: none;

    .experience-box {
      position: absolute;
      right: 0;
      top: 60px;
      margin-right: 0px;
      padding: 1.5rem;

      h5 {
        color: $experience-box-title-color;
        font-size: 1.25em;
        font-weight: 600;
      }

      p {
        font-size: 0.9em;
      }
    }
  }

  #map {
    height: 650px;
    width: 100%;

    .gm-style-cc {
      display: none !important;
    }

    .gm-style a[href^="https://maps.google.com/maps"] {
      display: none !important;
    }

    img {
      max-width: inherit !important;
    }
  }

  #map_over {
    padding-top: 0px;

    @include breakpoint(large) {
      padding: 0px;
      position: absolute;
    }

    top: 0px;
    left: 0px;
    position: relative;
  }
}

// CONTENT
@media print, screen and (min-width: 80em) {
  #content .title {
    font-size: 1.1rem;
    text-transform: capitalize;
  }
}

.grid-x.intro {
  margin: 2rem 0;
}

section.consulting {
  @include breakpoint(small only) {
    padding-top: 1.5rem;
  }

  > .grid-x {
    padding-bottom: 2.5rem;

    @include breakpoint(small) {
      padding-bottom: 0px;
    }
  }

  margin-bottom: 2.5rem;
  margin-top: 3rem;
}

section.experience {
  padding-top: 0px;
  margin-top: 3rem;
  @include breakpoint((small, medium)) {
    padding-bottom: 3rem;
  }
}
