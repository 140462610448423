.dropdown-container {
  position: relative;

  .dropdown-pane {
    width: 100%;
  }
}

.dropdown-pane {
  text-align: left;

  &.dropdown-context {
    // Triangle
    &::before {
      content: "";
      position: absolute;
      top: rem-calc(-5px);
      left: rem-calc( 25px);
      width: 0;
      height: 0;
      border-left: rem-calc(7px) solid transparent;
      border-right: rem-calc(7px) solid transparent;
      border-bottom: rem-calc(5px) solid $pn-dropdown-pane-context-background;
    }

    a {
      display: block;

      &:not(:last-of-type) {
        margin-bottom: $pn-dropdown-pane-context-a-margin-bottom;
      }
      &,
      &:hover,
      &:active,
      &:focus {
        color: $pn-dropdown-pane-context-a-color;
      }
      &:hover,
      &:active,
      &:focus {
        font-weight: $pn-dropdown-pane-context-a-hover-font-weight;
      }
    }
  }
  &.dropdown-select {
    padding: $pn-dropdown-pane-select-padding;
    box-shadow: $pn-dropdown-pane-select-box-shadow;
    background: $pn-dropdown-pane-select-background;
    font-size: $pn-button-font-size-large;
    color: $pn-dropdown-pane-select-color;

    &.dropdown-formfield {
      max-height: $pn-dropdown-pane-select-formfield-max-height;
      overflow-y: scroll;
    }

    > .dropdown-option {
      line-height: 1;
      border-radius: $pn-dropdown-pane-select-option-border-radius;
      padding: 0 $pn-dropdown-pane-select-option-padding;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        background: $pn-dropdown-pane-select-option-background-hover;
      }
      &:last-child {
        margin-bottom: $pn-dropdown-pane-select-option-last-margin-bottom;
      }
      &.selected {
        font-weight: $pn-dropdown-pane-select-option-selected-font-weight;
      }
      > span {
        display: inline-block;
        max-width: 100%;
        line-height: $pn-dropdown-pane-select-option-line-height;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
