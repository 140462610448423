.testimonials {

  .intro {
    p {
      @include breakpoint(medium only) {
        max-width: $pn-testimonials-p-max-width-medium;
      }

      &:last-of-type {
        @include breakpoint(small only) {
          margin-bottom: $pn-testimonials-p-margin-bottom-small;
        }

        @include breakpoint(medium only) {
          margin-bottom: $pn-testimonials-p-margin-bottom-medium;
        }

        @include breakpoint(large) {
          margin-bottom: $pn-testimonials-p-margin-bottom-large;
        }
      }
    }
  }

  .grid-main {
    justify-content: center;
  }

  .card {
    @include breakpoint(small only) {
      padding: $pn-testimonials-card-padding-small;
      text-align: center;

      &:not(:last-child) {
        margin-bottom: $pn-testimonials-card-margin-bottom-small;
      }
    }

    @include breakpoint(medium only) {
      padding: $pn-testimonials-card-padding-medium;

      &:not(:last-child) {
        margin-bottom: $pn-testimonials-card-margin-bottom-medium;
      }
    }

    @include breakpoint(large) {
      height: 100%;
      padding: $pn-testimonials-card-padding-large;

      &:not(:nth-child(-n+3)) {
        margin-top: $pn-testimonials-card-margin-top-large;
      }
    }
  }

  .name {
    margin-bottom: $pn-testimonials-name-margin-bottom;

    @include breakpoint(small only) {
      font-size: $pn-testimonials-name-font-size-small;
    }

    @include breakpoint(medium only) {
      font-size: $pn-testimonials-name-font-size-medium;
    }

    @include breakpoint(large) {
      font-size: $pn-testimonials-name-font-size-large;
    }
  }

  .role {
    font-size: $pn-testimonials-role-font-size;

    @include breakpoint(small only) {
      line-height: $pn-testimonials-role-line-height;
      margin-bottom: $pn-testimonials-role-margin-bottom-small;
    }

    @include breakpoint(medium only) {
      margin-bottom: $pn-testimonials-role-margin-bottom-medium;
    }

    @include breakpoint(large) {
      line-height: $pn-testimonials-role-line-height;
      margin-bottom: $pn-testimonials-role-margin-bottom-large;
    }
  }

  .text {
    @include breakpoint(small only) {
      margin-bottom: $pn-testimonials-text-margin-bottom-small;
    }

    @include breakpoint(medium only) {
      margin-bottom: $pn-testimonials-text-margin-bottom-medium;
    }

    @include breakpoint(large) {
      margin-bottom: $pn-testimonials-text-margin-bottom-large;
    }
  }

  .logo {
    @include breakpoint(medium only) {
      display: flex;

      @include flex-align(center);
    }

    >img {
      @include breakpoint(small only) {
        height: $pn-testimonials-img-height-small;
      }

      @include breakpoint(medium only) {
        position: relative;
        top: rem-calc(6px);
        height: $pn-testimonials-img-height-medium;
      }

      @include breakpoint(large) {
        height: $pn-testimonials-img-height-large;
      }
    }
  }

  .carousel-container {
    padding: 0;
  }

  .orbit {
    overflow: hidden;
  }

  .orbit-container {
    overflow: visible !important;
  }

  .orbit-slide {
    padding: 0 rem-calc($pn-grid-container-padding-small);
    transition-duration: $pn-testimonials-carousel-transition-duration;
  }

  .orbit-bullets {
    margin: $pn-testimonials-carousel-bullets-margin-top 0 0;
    font-size: 0;

    >button {
      &:focus {
        outline: none !important;
      }

      &:not(:last-of-type) {
        margin-right: $pn-testimonials-carousel-bullet-diameter;
      }
    }
  }
}