// Core dependencies
@import "bundle";

// Core global styles before further overrides by submodules
h1 {
  line-height: 1;
  text-transform: uppercase;
  font-family: Malva, $body-font-family;
  color: $pn-h1-color;
}

h2 {
  display: inline-block;
  position: relative;
  line-height: 1;

  @include breakpoint(medium down) {
    // height: $pn-h2-block-height-small;
    margin-bottom: $pn-h2-margin-bottom-small;
    padding-bottom: 1.4rem;
  }

  @include breakpoint(small only) {
    padding-bottom: 0.7rem;
  }

  @include breakpoint(large) {
    height: $pn-h2-block-height-large;
    margin-bottom: $pn-h2-margin-bottom-large;
  }

  &::after {
    display: flex;
    position: absolute;
    bottom: 0;
    font-size: 0;

    @include breakpoint(medium down) {
      content: $pn-svg-stripe-narrow;
      width: $pn-stripe-narrow-width;
      height: $pn-stripe-narrow-height;
    }

    @include breakpoint(large) {
      content: $pn-svg-stripe;
      width: $pn-stripe-width;
      height: $pn-stripe-height;
    }
  }

  &.h3-like {
    @function get-h3-font-size($breakpoint) {
      @return #{map-get(
          map-get(map-get($header-styles, $breakpoint), "h3"),
          "font-size"
        )}px;
    }

    @include breakpoint(small only) {
      height: $pn-h2-h3like-block-height-small;
      margin-bottom: $pn-h2-h3like-margin-bottom-small;
      font-size: get-h3-font-size("small");
    }

    @include breakpoint(medium only) {
      height: $pn-h2-h3like-block-height-medium;
      margin-bottom: $pn-h2-h3like-margin-bottom-medium;
      font-size: get-h3-font-size("medium");
    }

    @include breakpoint(large) {
      height: $pn-h2-h3like-block-height-large;
      margin-bottom: $pn-h2-h3like-margin-bottom-large;
      font-size: get-h3-font-size("large");
    }
  }

  .off-canvas & {
    height: auto;
    line-height: 1;
    margin-top: $pn-h2-offcanvas-margin-top;
    margin-bottom: $pn-h2-offcanvas-margin-bottom;
    font-size: $pn-h2-offcanvas-font-size;

    &::after {
      display: none;
    }
  }

  .remodal &,
  .vertical-off-canvas & {
    height: auto;
    line-height: 1;
    font-weight: $pn-h2-modal-font-weight;

    @include breakpoint(small only) {
      margin-top: $pn-h2-modal-margin-top-small;
      margin-bottom: $pn-h2-modal-margin-bottom-small;
      font-size: $pn-h2-modal-font-size-small;
    }

    @include breakpoint(medium only) {
      margin-top: $pn-h2-modal-margin-top-medium;
      margin-bottom: $pn-h2-modal-margin-bottom-medium;
      font-size: $pn-h2-modal-font-size-medium;
    }

    @include breakpoint(large) {
      margin-top: $pn-h2-modal-margin-top-large;
      margin-bottom: $pn-h2-modal-margin-bottom-large;
      font-size: $pn-h2-modal-font-size-large;
    }

    &::after {
      display: none;
    }

    > .emphasized {
      font-weight: $pn-h2-modal-emphasized-font-weight;
    }
  }
}

h5 {
  @include breakpoint(small only) {
    line-height: $pn-h5-line-height-small;
    margin-bottom: $pn-h5-margin-bottom-small;
  }

  @include breakpoint(medium only) {
    line-height: $pn-h5-line-height-medium;
    margin-bottom: $pn-h5-margin-bottom-medium;
  }

  @include breakpoint(large) {
    line-height: $pn-h5-line-height-large;
    margin-bottom: $pn-h5-margin-bottom-large;
  }
}

p {
  @include breakpoint(small only) {
    line-height: $pn-p-line-height-small;
    font-size: $pn-p-font-size-small;
  }

  @include breakpoint(medium only) {
    line-height: $pn-p-line-height-medium;
    font-size: $pn-p-font-size-medium;
  }

  @include breakpoint(large) {
    line-height: $pn-p-line-height-large;
    font-size: $pn-p-font-size-large;
  }
}

section {
  .name,
  .role {
    line-height: 1;
  }

  .name {
    font-weight: $pn-person-name-font-weigth;
  }

  .role {
    color: $pn-person-role-color;
  }
}

// Submodules in alphabetical order
@import "button";
@import "consulting";
@import "contact";
@import "content";
@import "dropdown";
@import "experience";
@import "fonts";
@import "footer";
@import "form";
@import "hamburger";
@import "header";
@import "input";
@import "join";
@import "layout";
@import "modal";
@import "nav";
@import "newsletter";
@import "solutions";
@import "team";
@import "insights";
@import "testimonials";
@import "single";
@import "sidebar";

@import "../partials/common";
@import "../partials/home";
@import "../partials/consulting";
@import "../partials/kms";
@import "../partials/can_we_help_floating";
@import "../partials/ma_landing";
