.solutions {
  @include breakpoint(small only) {
    margin-top: $pn-solutions-margin-top-small;
  }

  @include breakpoint(medium only) {
    margin-top: $pn-solutions-margin-top-medium;
  }

  @include breakpoint(large) {
    margin-top: $pn-solutions-margin-top-large;
  }

  .pictogram-container {
    &::after {
      background-image: $pn-svg-solutions;
    }
  }

  .text {
    @include breakpoint(small only) {
      margin-bottom: $pn-solutions-text-margin-bottom-small;
    }

    @include breakpoint(medium) {
      margin-bottom: $pn-solutions-text-margin-bottom-medium;
    }
  }

  .subsections {
    h4 {
      @include breakpoint(medium down) {
        margin-bottom: $pn-h2-margin-bottom-small;
      }

      @include breakpoint(large) {
        margin-bottom: $pn-h2-margin-bottom-large;
      }
    }

    p {
      @include breakpoint(small only) {
        font-size: $pn-solutions-subsection-p-font-size-small;
      }

      @include breakpoint(medium only) {
        font-size: $pn-solutions-subsection-p-font-size-medium;
      }

      @include breakpoint(large) {
        font-size: $pn-solutions-subsection-p-font-size-large;
      }
    }
  }

  .subsection {
    @include breakpoint(small only) {
      &:not(:first-child) {
        border-top: $pn-solutions-subsection-border-small;
        padding-top: $pn-box-padding-small;
      }
    }
  }
}