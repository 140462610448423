.team {
  &.full-width {
    padding-bottom: 3rem;
  }
  @include breakpoint(small only) {
  }

  @include breakpoint(medium only) {
    margin-top: $pn-team-margin-top-medium;
    border-bottom: none;
  }

  @include breakpoint(large) {
    margin-top: $pn-team-margin-top-large;
  }

  .intro {
    p {
      @include breakpoint(medium only) {
        max-width: $pn-team-p-max-width-medium;
      }

      &:last-of-type {
        @include breakpoint(small only) {
          margin-bottom: $pn-team-p-margin-bottom-small;
        }

        @include breakpoint(medium only) {
          margin-bottom: $pn-team-p-margin-bottom-medium;
        }

        @include breakpoint(large) {
          margin-bottom: $pn-team-p-margin-bottom-large;
        }
      }
    }
  }

  .grid-main {
    > .cell {
      @include breakpoint(small only) {
        &:not(:nth-child(-n + 2)) {
          margin-top: $pn-team-card-margin-top-small;
        }
      }

      @include breakpoint(medium only) {
        &:not(:nth-child(-n + 3)) {
          margin-top: $pn-team-card-margin-top-medium;
        }
      }

      @include breakpoint(large) {
        &:not(:nth-child(-n + 4)) {
          margin-top: $pn-team-card-margin-top-large;
        }
      }
    }
  }

  .stats-container {
    @include breakpoint(medium only) {
      display: flex;

      @include flex-align(right);
    }
  }

  .stats {
    text-align: right;

    @include breakpoint(medium only) {
      max-width: $pn-team-stats-max-width-medium;
      margin-top: rem-calc(30px);

      > .cell {
        $padding-base: rem-calc(10px);

        &:first-child {
          padding-right: $padding-base * 2;
        }

        &:nth-child(2) {
          padding-right: $padding-base;
        }

        &:last-child {
          padding-left: $padding-base;
        }
      }
    }

    @include breakpoint(large) {
      margin-top: rem-calc(44px);

      > .cell {
        $padding-base: rem-calc(20px);

        &:first-child {
          padding-right: $padding-base * 2;
        }

        &:nth-child(2) {
          padding-right: $padding-base;
        }

        &:last-child {
          padding-left: $padding-base;
        }
      }
    }
  }

  .number {
    line-height: 1;
    margin-bottom: $pn-team-stats-number-margin-bottom;
    font-weight: $pn-team-stats-number-font-weight;

    @include breakpoint(medium only) {
      font-size: $pn-team-stats-number-font-size-medium;
    }

    @include breakpoint(large) {
      font-size: $pn-team-stats-number-font-size-large;
    }
  }

  .text {
    color: $pn-team-stats-text-color;

    @include breakpoint(medium only) {
      line-height: $pn-team-stats-text-line-height-medium;
      font-size: $pn-team-stats-text-font-size-medium;
    }

    @include breakpoint(large) {
      line-height: $pn-team-stats-text-line-height-large;
      font-size: $pn-team-stats-text-font-size-large;
    }
  }

  .card {
    display: block; // for IE 11
    text-align: center;
    cursor: pointer;

    @include breakpoint(small only) {
      padding: 0 $pn-team-card-padding-sides-small $pn-team-card-padding-bottom-small;
    }

    @include breakpoint(medium only) {
      padding: 0 $pn-team-card-padding-sides-medium $pn-team-card-padding-bottom-medium;
    }

    @include breakpoint(large) {
      padding: 0 $pn-team-card-padding-sides-large $pn-team-card-padding-bottom-large;
      transform-origin: bottom;
      transition: box-shadow $pn-team-card-hover-transition-duration, transform $pn-team-card-hover-transition-duration;

      &:hover {
        box-shadow: $pn-team-card-hover-box-shadow;
        transform: scale($pn-team-card-hover-scale);

        img {
          filter: $pn-team-image-hover-filter;
        }
      }
    }

    img {
      @include breakpoint(small only) {
        padding: 0 $pn-team-image-padding-sides-small;
      }

      @include breakpoint(medium only) {
        padding: 0 $pn-team-image-padding-sides-medium;
      }

      @include breakpoint(large) {
        padding: 0 $pn-team-image-padding-sides-large;
        filter: $pn-team-image-filter;
        transition: filter $pn-team-card-hover-transition-duration;
      }
    }
  }

  .name {
    @include breakpoint(small only) {
      margin: $pn-team-name-margin-top-small 0 $pn-team-name-margin-bottom-small;
      font-size: $pn-team-name-font-size-small;
    }

    @include breakpoint(medium only) {
      margin: $pn-team-name-margin-top-medium 0 $pn-team-name-margin-bottom-medium;
      font-size: $pn-team-name-font-size-medium;
    }

    @include breakpoint(large) {
      margin: $pn-team-name-margin-top-large 0 $pn-team-name-margin-bottom-large;
      font-size: $pn-team-name-font-size-large;
    }
  }

  .role {
    margin-bottom: 0;

    @include breakpoint(small only) {
      font-size: $pn-team-role-font-size-small;
    }

    @include breakpoint(medium only) {
      font-size: $pn-team-role-font-size-medium;
    }

    @include breakpoint(large) {
      font-size: $pn-team-role-font-size-large;
    }
  }

  @at-root .remodal,
    .vertical-off-canvas {
    p {
      @include breakpoint(small only) {
        line-height: $pn-team-modal-p-line-height-small;
        font-size: $pn-team-modal-p-font-size-small;
      }

      @include breakpoint(medium only) {
        line-height: $pn-team-modal-p-line-height-medium;
        font-size: $pn-team-modal-p-font-size-medium;

        &:last-of-type {
          margin-bottom: $pn-team-modal-p-margin-bottom-medium;
        }
      }

      @include breakpoint(large) {
        line-height: $pn-team-modal-p-line-height-large;
        font-size: $pn-team-modal-p-font-size-large;
      }
    }

    .photo-column {
      @include breakpoint(small only) {
        text-align: center;
      }

      @include breakpoint(large) {
        img {
          position: absolute;
          bottom: 0;
          left: 0;

          // for IE 11
          @include breakpoint(small only) {
            width: $pn-team-modal-photo-width-small;
          }

          @include breakpoint(medium only) {
            width: $pn-team-modal-photo-width-medium;
          }

          @include breakpoint(large) {
            width: $pn-team-modal-photo-width-large;
          }
        }
      }
    }

    .details-column {
      .nametag {
        @include breakpoint(small only) {
          text-align: center;
        }
      }

      .role {
        @include breakpoint(small only) {
          margin-bottom: $pn-team-modal-role-margin-bottom-small;
          font-size: $pn-team-modal-role-font-size-small;
        }

        @include breakpoint(medium only) {
          margin-bottom: $pn-team-modal-role-margin-bottom-medium;
          font-size: $pn-team-modal-role-font-size-medium;
        }

        @include breakpoint(large) {
          margin-bottom: $pn-team-modal-role-margin-bottom-large;
          font-size: $pn-team-modal-role-font-size-large;
        }
      }

      .about {
        @include breakpoint(large) {
          max-height: $pn-team-modal-about-max-height-large;
          overflow-y: auto;
        }
      }

      .skillset {
        ul {
          line-height: 1;
          font-size: $pn-team-modal-skillset-font-size;
          font-weight: $pn-team-modal-skillset-font-weight;
          list-style: none;

          @include breakpoint(small only) {
            margin-bottom: $pn-team-modal-skillset-list-margin-bottom-small !important;
          }

          @include breakpoint(medium) {
            margin-bottom: $pn-team-modal-bottommost-margin-bottom !important;
          }
        }

        li {
          margin-bottom: $pn-team-modal-skillset-item-margin-bottom;

          &:last-of-type {
            @include breakpoint(medium) {
              margin-bottom: 0;
            }
          }

          &:not(:nth-of-type(-n + #{$pn-team-modal-skillset-list-length-medium-down})) {
            @include breakpoint(medium down) {
              display: none;
            }
          }

          &::before {
            content: "\e5ca";
            vertical-align: text-bottom;
            margin-right: $pn-team-modal-skillset-item-icon-margin-right;
            font-family: "Material Icons";
            color: $pn-team-modal-skillset-item-icon-color;
          }
        }
      }
    }

    .contact {
      @include breakpoint(large) {
        position: absolute;
        bottom: 0;
        width: $pn-team-modal-contact-width;
        height: $pn-team-modal-contact-height;
      }
    }

    .contact-group {
      @include breakpoint(small only) {
        margin-top: $pn-team-modal-contact-group-margin-top-small;

        &:last-of-type {
          margin-bottom: $pn-team-modal-bottommost-margin-bottom;
        }
      }

      @include breakpoint(medium only) {
        margin-top: $pn-team-modal-contact-group-margin-top-medium;

        &:first-of-type {
          margin-top: $pn-team-modal-contact-group-first-margin-top-medium;
        }

        &:last-of-type {
          margin-bottom: $pn-team-modal-bottommost-margin-bottom;
        }
      }

      @include breakpoint(large) {
        margin-top: $pn-team-modal-contact-group-margin-top-large;
      }
    }

    .contact-label {
      line-height: 1;
      margin-bottom: $pn-team-modal-contact-label-margin-bottom;
      text-transform: uppercase;
      font-size: $pn-team-modal-contact-label-font-size;
      color: $pn-team-modal-contact-label-color;
    }

    .contact-data {
      line-height: 1;
      font-size: $pn-team-modal-contact-data-font-size;
      font-weight: $pn-team-modal-contact-data-font-weight;

      > a {
        color: $pn-button-secondary-background;

        &:hover,
        &:active,
        &:focus {
          color: $pn-button-secondary-background-hover;
        }
      }
    }
  }
}
