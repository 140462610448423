nav {
  position: sticky;
  top: 0;
  z-index: 100;
  background: $pn-nav-background;

  &.orbit-bullets {
    background: none;
  }

  @include breakpoint(medium only) {
    height: $pn-nav-height-medium;
    box-shadow: $pn-nav-box-shadow-medium;
  }

  @include breakpoint(large) {
    height: $pn-nav-height-large;
    box-shadow: $pn-nav-box-shadow-large;
  }

  li {
    display: inline-block;

    @include breakpoint(medium only) {
      margin-right: $pn-nav-item-margin-right-medium;
    }

    @include breakpoint(large) {
      margin-right: $pn-nav-item-margin-right-large;
    }

    &.right {
      float: right;
      margin-right: 0;
    }
  }

  a {
    padding: 0 !important;

    @include breakpoint(medium only) {
      line-height: $pn-nav-height-medium;
      font-size: $pn-nav-font-size-medium;
    }

    @include breakpoint(large) {
      line-height: $pn-nav-height-large;
      font-size: $pn-nav-font-size-large;
    }

    &,
    &:hover,
    &:active,
    &:focus {
      color: $pn-nav-item-color;
    }

    &.currElement {
      font-weight: $pn-nav-item-current-font-weight;
    }
  }

  .navbar {
    width: 100%;
  }

  .hoverline {
    bottom: 0;
    height: $pn-nav-hoverline-height !important;
    background-color: $pn-nav-hoverline-background !important;
  }
}