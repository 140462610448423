.sidebar {
  border-left: 1px solid #dfe0e6;
  padding-left: 2rem;
  height: 100%;
  @include breakpoint(medium only) {
    padding: 0;
    margin: 6rem;
    margin-top: 0;
    border-left: 0;
    min-height: unset !important;
  }
  @include breakpoint(small only) {
    padding: 0;
    margin: 0;
    border-left: 0;
    min-height: unset !important;
  }
  

  .stuck {
    position: fixed;
    top: 0;
  }
}
#newsletter-sidebar {
  padding-top: 30px;
  border-top: 1px solid transparent;
}
