.join {
  border-style: solid;
  border-color: $pn-join-border-color;
  background: $pn-join-background;
  margin-top: 0;

  @include breakpoint(small only) {
    border-width: $pn-join-border-width-small 0 $pn-join-border-width-small;
    padding-top: $pn-join-padding-top-small;
    padding-bottom: $pn-join-padding-bottom-small;
  }
  @include breakpoint(medium only) {
    border-width: $pn-join-border-width-medium 0 $pn-join-border-width-medium;
    padding-top: $pn-join-padding-top-medium;
    padding-bottom: $pn-join-padding-bottom-medium;
  }
  @include breakpoint(large) {
    border-width: $pn-join-border-width-large;
    padding-top: $pn-join-padding-top-large;
    padding-bottom: $pn-join-padding-bottom-large;
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .grid-container {
    width: 100%; // for IE 11
  }

  h2 {
    font-size: 1.5rem;
    &::after {
      display: -ms-flexbox;
      display: flex;
      font-size: 0;
      margin-top: 0.6rem;
      position: relative !important;
    }
  }
  .open-positions {
    margin-bottom: 2rem;
    > * {
      display: block;
      margin-bottom: 0.5rem;
    }
    span {
      font-weight: 600;
    }
    font-size: 1.25rem;
    .link.red {
      color: #fc1e00;
    }
  }
}
