.newsletter {
  @include breakpoint(medium only) {
    margin-top: $pn-newsletter-margin-top-medium;
  }

  @include breakpoint(large) {
    margin-top: $pn-newsletter-margin-top-large;
  }

  h2 {
    text-transform: uppercase;

    @include breakpoint(large) {
      height: $pn-newsletter-h2-block-height-large;
    }
  }

  p {
    @include breakpoint(medium only) {
      font-size: $pn-newsletter-p-font-size-medium;
      line-height: $pn-newsletter-p-line-height-medium;
    }

    @include breakpoint(large) {
      font-size: $pn-newsletter-p-font-size-large;
      line-height: $pn-newsletter-p-line-height-large;
    }
  }

  input {
    @include breakpoint(medium only) {
      margin-top: $pn-newsletter-input-margin-top-medium;
      margin-bottom: $pn-newsletter-input-margin-bottom-medium;
    }

    @include breakpoint(large) {
      margin-top: $pn-newsletter-input-margin-top-large;
      margin-bottom: $pn-newsletter-input-margin-bottom-large;
    }
  }

  .grid-main {
    > .cell {
      &:first-of-type {
        align-self: flex-end;
      }

      &:last-of-type {
        align-self: flex-start;
      }
    }
  }

  .form-label {
    display: none;
  }

  .button {
    margin: 0;
  }

  .newsletter-content > div:first-child {
    @include breakpoint(large only) {
      padding-right: 1rem;
    }
    @include breakpoint(medium only) {
      margin-bottom: 1rem;
    }
    @include breakpoint(small only) {
      margin-bottom: 1rem;
    }
  }
}

#newsletter {
  .agree {
    label {
      width: 100%;
      margin: 0;

      &::before {
        margin-right: 1rem;
        content: "";
        display: flex;
        float: left;
        border-radius: $pn-form-agree-checkbox-border-radius;
        font-family: "Material Icons";
        font-size: $pn-form-agree-checkbox-font-size;
        background: $pn-form-agree-checkbox-background;
        color: $pn-form-agree-checkbox-color;

        @include flex-align(center, middle);

        @include breakpoint(small only) {
          width: $pn-form-agree-checkbox-width-small;
          height: $pn-form-agree-checkbox-width-small;
        }

        @include breakpoint(medium only) {
          width: $pn-form-agree-checkbox-width-medium;
          height: $pn-form-agree-checkbox-width-medium;
        }

        @include breakpoint(large) {
          width: $pn-form-agree-checkbox-width-large;
          height: $pn-form-agree-checkbox-width-large;
        }
      }
    }

    [type="checkbox"] {
      position: absolute;
      height: auto !important;
      margin: 0;
      opacity: 0;
      cursor: pointer;

      &:checked {
        + label {
          &::before {
            content: "\e5ca";
            background: $pn-form-agree-checkbox-checked-background;
          }
        }
      }
    }
  }
}

.sidebar-newsletter {
  .newsletter {
    @include breakpoint(large only) {
      padding: 0 !important;
      > .grid-main {
        margin: 0 !important;
      }
    }
    @include breakpoint(small only) {
      padding: 0;
    }
    .newsletter-content {
      @include breakpoint(small only) {
        padding: 1rem;
        padding-bottom: 0rem;
      }
    }
    h2 {
      @include breakpoint(medium only) {
        margin-top: 0.7rem;
      }
      @include breakpoint(small only) {
        margin-top: 0.7rem;
      }
      text-transform: initial;
      font-size: 1.25rem;
      line-height: 1.5;
      height: 3rem;
      margin-bottom: 1rem;
    }
    .grid-main {
      padding: 0.25rem;
      padding-top: 0.5rem;
      border: 0.1rem solid #e0e1e6;
    }
    .form-input-wrapper {
      input {
        margin-top: 0;
        padding: 0.6rem;
        height: auto !important;
        font-size: 14px;
        margin-bottom: 0.75rem;
      }
    }
    form {
      @include breakpoint(medium only) {
        margin-bottom: 0.7rem;
      }
      @include breakpoint(small only) {
        margin-bottom: 0.7rem;
      }
      button[type="submit"] {
        margin-bottom: 0.75rem;
        border-radius: 4px;
        margin-top: 0.15rem;
      }
    }
  }
  #newsletter {
    .agree {
      label {
        p {
          font-size: 12px;
          line-height: 14px;
        }
        &:before {
          border-radius: 0;
          min-width: 1.875rem;
          min-height: 1.875rem;
        }
      }
    }
  }
}

.grid-container.box.box-dim.newsletter {
  @include breakpoint(small) {
    // background: #f7f7f7;
    .grid-x.grid-main {
      padding: 1rem;
      h2.content-block {
        padding-left: 0;
      }
    }
  }
}

.newsletter-home.grid-container.box.box-dim.newsletter {
  background: #f7f7f7;
  @include breakpoint(medium only) {
    padding: 0;
  }
  @include breakpoint(large only) {
    padding: 0;
  }
  .grid-x.grid-main {
    padding: 1.75rem 2rem;
  }
  input {
    margin-top: 0;
  }
  button[type="submit"].button.secondary.expanded {
    height: 3rem;
  }
}
