input {
  @include breakpoint(small only) {
    height: $pn-button-line-height-small !important;
  }
  @include breakpoint(medium only) {
    height: $pn-button-line-height-medium !important;
  }
  @include breakpoint(large) {
    height: $pn-button-line-height-large !important;
  }

  &::placeholder {
    color: $pn-input-placeholder-color;
    opacity: 1;
  }
  &.is-invalid-input {
    $padding-sides: $pn-input-padding-sides - $pn-input-error-border-width !important;

    border: $pn-input-error-border-width solid $pn-input-error-color !important;
    padding-left: $padding-sides;
    padding-right: $padding-sides;
    background: $pn-input-error-background !important;
  }
}

label {
  &.is-invalid-label {
    // checkboxes
    &::before {
      border: $pn-input-error-border-width solid $pn-input-error-color !important;
      background: $pn-input-error-background !important;
    }

    // fields
    &,
    &::after {
      color: $pn-input-error-color !important;
    }
  }
}
