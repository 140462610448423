.experience {
  @include breakpoint(small only) {
    // margin-top: $pn-experience-margin-top-small;
  }

  @include breakpoint(medium only) {
    // margin-top: $pn-experience-margin-top-medium;
  }

  @include breakpoint(large) {
    // margin-top: $pn-experience-margin-top-large;
  }

  .grid-container {
    @include breakpoint(medium only) {
      padding-top: $pn-experience-padding-top-medium;
      padding-bottom: $pn-experience-padding-bottom-medium;
    }

    @include breakpoint(large) {
      border-right: $pn-framed-border;
      padding-top: $pn-experience-padding-top-large;
      padding-bottom: $pn-experience-padding-bottom-large;
      background-image: $pn-image-map;
      background-position: top right;
      background-size: contain;
      background-repeat: no-repeat;
    }

    @include breakpoint(large) {
      @include breakpoint(retina) {
        background-image: $pn-image-map-retina;
      }
    }
  }

  .intro {
    p {
      @include breakpoint(medium only) {
        max-width: $pn-experience-p-max-width-medium;
      }

      &:last-of-type {
        @include breakpoint(small only) {
          margin-bottom: $pn-experience-p-margin-bottom-small;
        }

        @include breakpoint(medium only) {
          margin-bottom: $pn-experience-p-margin-bottom-medium;
        }

        @include breakpoint(large) {
          margin-bottom: $pn-experience-p-margin-bottom-large;
        }
      }
    }
  }

  .card {
    @include breakpoint(small only) {
      padding: $pn-experience-card-padding-small;

      &:not(:last-of-type) {
        margin-bottom: $pn-experience-card-margin-bottom-small;
      }
    }

    @include breakpoint(medium only) {
      max-width: $pn-experience-card-max-width-medium;
      padding: $pn-experience-card-padding-medium;
    }

    @include breakpoint(large) {
      padding: $pn-experience-card-padding-large;
    }
  }

  ul {
    margin: 0;
    list-style: none;

    @include breakpoint(small only) {
      line-height: $pn-experience-ul-line-height-small;
      font-size: $pn-experience-ul-font-size-small;
    }
  }
}