// Material Icons

@font-face {
  font-family: "Material Icons";
  src: url("../fonts/Material Icons/materialicons-regular-webfont.woff2") format("woff2"),
    url("../fonts/Material Icons/materialicons-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

//Malva
@font-face {
  font-family: "Malva";
  src: url("../fonts/Malva/MalvaBlack.eot");
  src: url("../fonts/Malva/MalvaBlack.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Malva/MalvaBlack.woff2") format("woff2"), url("../fonts/Malva/MalvaBlack.woff") format("woff"),
    url("../fonts/Malva/MalvaBlack.ttf") format("truetype");
  font-weight: 900;
}

// Lato
@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/lato-regular-webfont.woff2") format("woff2"),
    url("../fonts/Lato/lato-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/lato-bold-webfont.woff2") format("woff2"),
    url("../fonts/Lato/lato-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/lato-black-webfont.woff2") format("woff2"),
    url("../fonts/Lato/lato-black-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
