%textIcon {
  position: relative;
  border: none !important;
  font-family: "Material Icons";
  display: inline-block;
  margin-right: 0.25rem;
}

$insight-box-padding: 1.5rem;

section.insights.full-width.framed {
  @include breakpoint(small only) {
  }

  @include breakpoint(medium only) {
  }

  @include breakpoint(large) {
    margin-top: 3rem;
  }

  border-bottom: none;

  .insight-box {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    transition: box-shadow 0.2s;
    transition-property: box-shadow;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
    margin-bottom: 2rem;

    &:hover {
      box-shadow: 0.3125rem 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.06);
    }

    .date-box {
      @include breakpoint(small only) {
        margin-bottom: 0;
      }
      @include breakpoint(medium only) {
        margin-bottom: 0;
      }

      background-color: #f0f0f0;
      margin: $insight-box-padding;
      padding: 0.75rem 1rem;
      width: 5rem;
      text-align: center;
      border-radius: 1rem;

      .month {
        font-weight: 800;
        font-size: 18px;
        color: #e34d3f;
      }

      .day {
        font-size: 29px;
        font-weight: 800;
        line-height: 29px;
      }
    }

    .title {
      h2 {
        @include breakpoint(small only) {
          margin-bottom: 0;
          padding-bottom: 0;
        }
        @include breakpoint(medium only) {
          margin-bottom: 0;
          padding-bottom: 0;
        }
        &:after {
          content: "";
        }
        font-size: 22px;
        height: unset;
        margin-bottom: 1rem;
      }
    }

    .header {
      @include breakpoint(small only) {
        height: 160px;
      }
      @include breakpoint(medium only) {
        height: 160px;
      }
      height: 200px;
      background-color: #fafafa;
      background-position: center;
      background-size: cover;
    }

    .description {
      p img {
        display: none;
      }
    }

    .content {
      padding: $insight-box-padding;
      flex: 1;

      .label {
        @include breakpoint(small only) {
          margin-bottom: 0.4rem;
        }
        @include breakpoint(medium only) {
          margin-bottom: 0.4rem;
        }
        margin-bottom: 1rem;
        color: #9e9e9e;
        font-size: 14px;

        .location {
          display: flex;
          &::before {
            @extend %textIcon;
            content: url(../images/location.svg);
            top: 4px;
            margin-right: 0.75rem;
          }
        }
        .readtime::before {
          @extend %textIcon;
          content: "";
          display: inline-block;
          width: 5px;
          height: 5px;
          background: #dfe0e6;
          border-radius: 20px;
          margin-bottom: 2px;
        }
      }
    }

    .footer {
      @include breakpoint(small only) {
        padding-bottom: 1rem;
      }
      @include breakpoint(medium only) {
      }
      padding: $insight-box-padding;
      padding-bottom: 0.75rem;
      padding-top: 0;

      display: flex;
      flex-direction: row;

      & > div:last-child {
        flex: 1;
      }

      .button {
        @include breakpoint(small only) {
          font-size: 13px;
        }
        @include breakpoint(medium only) {
          font-size: 13px;
        }
        font-size: 14px;
        margin: 0;
        width: auto;

        &.tag {
          border: 1px solid !important;
          display: inline;
          padding: 0.3rem 0.6rem;
          font-weight: 100;
        }
        &.red {
          color: #e34d3f;
        }

        &.download {
          @include breakpoint(small only) {
            font-size: 13px;
          }
          @include breakpoint(medium only) {
            font-size: 13px;
          }
          &:before {
            @extend %textIcon;
            content: url(../images/download.svg);
            display: inline-block;
            transform: scale(0.9);
            top: 1px;
          }
          &:hover {
            background: transparent;
            text-decoration: underline;
          }
        }
      }

      .action {
        a {
          padding-left: 0.75rem;
          &:after {
            @extend %textIcon;
            content: "\e5c5";
            transform: rotate(-90deg);
            top: 0.1rem;
          }
        }
      }
    }
  }
  .all-articles {
    text-align: right;
    width: 100%;
  }
  .insights-articles .hidden {
    height: 0;
    overflow: hidden;
    transition: 0.25s ease-out;
  }
}
