.consulting {
    .card {
        >[class^=grid-] {
            @include breakpoint(small only) {
                height: unset !important;
            }

            @include breakpoint(medium only) {
                height: unset !important;
            }

            @include breakpoint(large) {
                height: unset !important;
            }
        }
    }

    .description {
        .description-text {
            p:first-child {
                margin-bottom: 2rem;
            }
        }

        &::before {
            height: 6.25rem;
        }
    }

    .action {
        align-self: unset;
    }

    .intro {
        @include breakpoint(medium only) {
            margin: 0;
        }

        @include breakpoint(large only) {
            margin: 0;
        }

        @include breakpoint(small only) {
            padding: 1rem;
        }

        p {
            @include breakpoint(small only) {
                max-width: 100%;
            }
        }
    }

    ol{
        li{
            a{
                position: absolute;
                content: '';
                top: -13px;
                left: -22px;
                width: 100%;
                height: 100%;
            }
        }
    }
}

header a.arrow {
    @include breakpoint(small only) {}

    @include breakpoint(medium only) {}
}

hr {
    width: 100%;
}

#contact.consulting,
footer.consulting {
    background-color: #0a1c48;

    .grid-x {
        background-color: white;
    }
}

footer.consulting {
    margin-top: 0px;
    padding-top: 0px;

    .grid-x {
        padding: 2rem 0;
        padding-bottom: 1.66rem;
        background: #f0f0f0;
    }
}